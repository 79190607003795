import React, { useState, useEffect } from 'react';
import { Box, TextField, FormControlLabel, Switch } from "@mui/material";
import { useDispatch } from 'react-redux';
import { updateItem } from '../../reduxstore/itemsSlice';

const ConfigurationView = ({ selectedItem, itemStatus, projectId }) => {

  const dispatch = useDispatch();
  const [localConfig, setLocalConfig] = useState({
    title: selectedItem?.updated_config?.title || '',
    description: selectedItem?.updated_config?.description || '',
    type: selectedItem?.updated_config?.type || 'Free Text'
  });

  const hasChildSections = selectedItem?.sections?.length > 0 || selectedItem?.subsections?.length > 0;

  useEffect(() => {
    setLocalConfig({
      title: selectedItem?.updated_config?.title || '',
      description: selectedItem?.updated_config?.description || '',
      type: selectedItem?.updated_config?.type || 'Free Text'
    });
  }, [selectedItem]);

  const updateConfig = (updates) => {
    dispatch(updateItem({
      projectId: projectId,
      uuid: selectedItem.uuid,
      key: 'updated_config',
      value: {
        ...selectedItem.updated_config,
        ...updates
      }
    }))
  };

  const handleBlur = (field, value) => {
    if (value !== selectedItem?.updated_config?.[field]) {
      console.log(field, value)
      updateConfig({ [field]: value });
    }
  };

  return (
    <Box style={{
      height: "100%",
      overflowY: "auto",
      padding: "1rem",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            size="small"
            value={localConfig.title}
            onChange={(e) => setLocalConfig(prev => ({ ...prev, title: e.target.value }))}
            onBlur={(e) => handleBlur('title', e.target.value)}
            disabled={itemStatus?.isGenerating}
            InputProps={{
              sx: {
                fontSize: "0.875rem",
                color: "#666666",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }
            }}
            sx={{ mt: 1 }}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            size="small"
            multiline
            rows={4}
            value={localConfig.description}
            onChange={(e) => setLocalConfig(prev => ({ ...prev, description: e.target.value }))}
            onBlur={(e) => handleBlur('description', e.target.value)}
            disabled={itemStatus?.isGenerating}
            InputProps={{
              sx: {
                fontSize: "0.875rem",
                color: "#666666",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }
            }}
            sx={{ mt: 1 }}
          />
        </Box>

        {!hasChildSections && (
          <Box>
            <FormControlLabel
              sx={{
                fontSize: "0.875rem",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.875rem"
                },
                pl: 0.1
              }}
              control={
                <Switch
                  sx={{
                    color: "#666666"
                  }}
                  checked={localConfig.type === "Form"}
                  onChange={(e) => {
                    const newType = e.target.checked ? "Form" : "Free Text";
                    setLocalConfig(prev => ({ ...prev, type: newType }));
                    updateConfig({ type: newType });
                  }}
                  disabled={itemStatus?.isGenerating}
                />
              }
              label="Attachment"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConfigurationView;