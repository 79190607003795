import React from 'react';
import {
  Box,
  ListItem,
  ListItemText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SourceSearch from './SourceSearch';
import { api } from "../../store";
import { useDispatch } from 'react-redux';
import { updateItem } from '../../reduxstore/itemsSlice';

const SourceItem = ({
  source,
  expanded,
  handleSourceExpansion,
  selectedItem,
  getFileNameFromPath,
  handleArchiveFile,
  dispatch,
  disabled
}) => {
  const fileName = getFileNameFromPath(source.source_path);

  return (
    <Accordion
      expanded={expanded === source.uuid}
      onChange={handleSourceExpansion(source.uuid)}
      disableGutters
      sx={{
        display: 'block',
        px: 1
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls={`panel-${source.uuid}-content`}
        id={`panel-${source.uuid}-header`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ flexGrow: 1, overflow: 'hidden', wordBreak: 'break-word' }}>
            <Typography variant="primaryText">
              [{source.uuid}] {source.metadata.title}
            </Typography>
            <Tooltip title={fileName} arrow>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                  fontSize: '0.75rem',
                  lineHeight: 1.2,
                  display: 'block',
                  textOverflow: 'wrap',
                }}
              >
                {fileName}
              </Typography>
            </Tooltip>
          </Box>
          {!disabled && (
            <IconButton
              size="small"
              sx={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                const updatedSources = selectedItem.updated_config.sources.filter(s => s.uuid !== source.uuid);
                dispatch(updateItem({
                  uuid: selectedItem.uuid,
                  key: 'updated_config',
                  value: {
                    ...selectedItem.updated_config,
                    sources: updatedSources
                  }
                }));
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ overflowX: 'hidden', width: '100%', maxWidth: '100%' }}>
        <Typography
          variant="primaryText"
          className="typography-content"
          dangerouslySetInnerHTML={{ __html: source.extract }}
          sx={{ overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', width: '100%' }}
        />
        <br />
        <br />
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%' }}>
          <Tooltip title={fileName || "No path available"} arrow>
            <Typography variant="primaryText" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 'calc(100% - 24px)' }}>
              Source: {fileName || "No path available"}
            </Typography>
          </Tooltip>
          <LaunchRoundedIcon
            onClick={() => handleArchiveFile(source.source_path, source.start_time)}
            style={{ cursor: "pointer", flexShrink: 0 }}
            fontSize="small"
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const SourcesView = ({
  sourceSearchOpen,
  setSourceSearchOpen,
  sourcesToDisplay,
  expanded,
  setExpanded,
  userArchiveIDs,
  rowData,
  selectedItem,
  itemStatus,
  projectId
}) => {
  const dispatch = useDispatch();

  const handleSourceExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getFileNameFromPath = (path) => {
    if (path !== undefined) {
      const foundItem = rowData.find((item) => item.id === path);
      return foundItem ? foundItem.name : path;
    }
    return path;
  };

  const handleArchiveFile = async (path, start_time) => {
    try {
      let foundItem = rowData.find((item) => item.id === path);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);

      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });

      const contentType = response.data.fileType;

      if (contentType.includes("video")) {
        setShowVideoModal(true);
        setVideoUrl(response.data.url);
      } else if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      {sourceSearchOpen ? (
        <SourceSearch
          archive_ids={userArchiveIDs}
          onCancel={() => setSourceSearchOpen(false)}
          rowData={rowData}
          sourceCount={sourcesToDisplay.length}
          selectedItem={selectedItem}
          projectId={projectId}
        />
      ) : (
        <>
          {sourcesToDisplay && sourcesToDisplay.length > 0 ? (
            [...sourcesToDisplay]
              .sort((a, b) => (a.uuid > b.uuid ? 1 : -1))
              .map((source, index) => (
                <SourceItem
                  key={index}
                  source={source}
                  expanded={expanded}
                  handleSourceExpansion={handleSourceExpansion}
                  selectedItem={selectedItem}
                  getFileNameFromPath={getFileNameFromPath}
                  handleArchiveFile={handleArchiveFile}
                  dispatch={dispatch}
                  disabled={itemStatus?.isGenerating}
                />
              ))
          ) : (
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: "0.875rem",
                    lineHeight: 1.2,
                    color: "#757575",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                  },
                }}
                primary="No sources available."
              />
            </ListItem>
          )}

          {(!selectedItem?.sections?.length && !selectedItem?.subsections?.length && !itemStatus?.isGenerating) && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setSourceSearchOpen(true)}
              sx={{ my: 1, ml: 2 }}
            >
              Add Source
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default SourcesView;