import React from 'react';
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const ActionButtons = ({ onRegenerate, selectedItem }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const itemStatus = useSelector(state =>
    state.itemsStatus.find(item => item.uuid === selectedItem?.uuid)
  );

  const differences = React.useMemo(() => {
    const diffs = [];
    if (!selectedItem?.updated_config) return diffs;

    if (selectedItem.title !== selectedItem.updated_config.title) {
      diffs.push('title');
    }
    if (selectedItem.description !== selectedItem.updated_config.description) {
      diffs.push('description');
    }
    if (selectedItem.type !== selectedItem.updated_config.type) {
      diffs.push('type');
    }
    if (selectedItem.sources !== selectedItem.updated_config.sources) {
      if (selectedItem.sources.length !== selectedItem.updated_config.sources.length) {
        diffs.push('sources');
      } else {
        const selectedSourceUUIDs = new Set(selectedItem.sources.map(source => source.uuid));
        const updatedSourceUUIDs = new Set(selectedItem.updated_config.sources.map(source => source.uuid));

        const missingInUpdated = [...selectedSourceUUIDs].filter(uuid => !updatedSourceUUIDs.has(uuid));
        const missingInOriginal = [...updatedSourceUUIDs].filter(uuid => !selectedSourceUUIDs.has(uuid));

        if (missingInUpdated.length > 0 || missingInOriginal.length > 0) {
          diffs.push('sources');
        }
      }
    }
    if (selectedItem.requirements !== selectedItem.updated_config.requirements) {
      diffs.push('requirements');
    }

    return diffs;
  }, [selectedItem]);

  const disableRegenerate = React.useMemo(() => {
    return !differences.length || itemStatus?.isGenerating;
  }, [differences, itemStatus]);

  const hasChildSections = selectedItem?.sections?.length > 0 || selectedItem?.subsections?.length > 0;

  return (
    <Box sx={{ width: "30%", height: "3rem", display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 0.2, alignItems: 'center', width: '100%', pr: 1 }}>
        {!hasChildSections && (
          isSmallScreen ? (
            <IconButton
              size="small"
              title="Regenerate"
              color="primary"
              onClick={onRegenerate}
              disabled={disableRegenerate}
              sx={{ py: 0.5 }}
            >
              <AutoModeRoundedIcon />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              size="small"
              title="Regenerate"
              onClick={onRegenerate}
              disabled={disableRegenerate}
              color="primary"
              startIcon={<AutoModeRoundedIcon />}
              sx={{ py: 0.5 }}
            >
              {itemStatus?.isGenerating ? 'Generating...' : 'Regenerate'}
            </Button>
          )
        )}
      </Box>
    </Box>
  );
};

export default ActionButtons;