import React, { useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-documenteditor/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';

const DocxEditor = forwardRef(({ fileUrl, initialPage }, ref) => {
  const docxViewerRef = useRef(null);

  const toolbarItems = [
    'PageNavigationTool',
    'ZoomTool',
    { type: 'Separator' },
    'SearchOption',
    'PageNumber'
  ];

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    getCurrentPage: () => {
      if (docxViewerRef.current?.documentEditor) {
        return docxViewerRef.current.documentEditor.selection.currentPage;
      }
      return 1;
    },
    goToPage: (pageNumber) => {
      if (docxViewerRef.current?.documentEditor) {
        const editor = docxViewerRef.current.documentEditor;
        // Get total pages
        const pageCount = editor.pageCount;
        // Ensure page number is within bounds
        const targetPage = Math.min(Math.max(1, pageNumber), pageCount);
        // Navigate to the page
        editor.scrollToPage(targetPage);
      }
    },
    viewer: docxViewerRef.current
  }));

  // Handle document loaded event
  const handleDocumentChange = () => {
    if (docxViewerRef.current?.documentEditor && initialPage > 1) {
      setTimeout(() => {
        docxViewerRef.current.documentEditor.scrollToPage(initialPage);
      }, 100);
    }
  };

  useEffect(() => {
    const loadDocument = async () => {
      if (!fileUrl) return;

      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        const formData = new FormData();
        formData.append('files', blob, 'document.docx');

        const convertResponse = await fetch('https://ej2services.syncfusion.com/production/web-services/api/documenteditor/import', {
          method: 'POST',
          body: formData
        });

        if (!convertResponse.ok) {
          throw new Error('Failed to convert document');
        }

        const sfdtContent = await convertResponse.text();

        if (docxViewerRef.current) {
          const container = docxViewerRef.current;
          const documentEditor = container.documentEditor;

          documentEditor.open(sfdtContent);

          // Navigate to initial page after document is loaded
          handleDocumentChange();
        }
      } catch (error) {
        console.error('Error loading document:', error);
      }
    };

    loadDocument();
  }, [fileUrl, initialPage]);

  const onCreated = () => {
    if (docxViewerRef.current) {
      const container = docxViewerRef.current;
      const documentEditor = container.documentEditor;

      // Disable file menu and other editing features
      documentEditor.enableFileMenu = false;
      documentEditor.pageOutline = true;
      documentEditor.enableLocalPaste = false;
      documentEditor.isReadOnly = true;

      documentEditor.selectionChange = () => {
        const currentPage = documentEditor.selection.currentPage;
        console.log('Page changed to:', currentPage);
      };

      // Hide the properties pane
      if (container.propertiesPane) {
        container.propertiesPane.destroy();
      }
    }
  };

  const handlePageChange = () => {
    console.log("coming here");
    if (docxViewerRef.current?.documentEditor) {
      const currentPage = docxViewerRef.current.documentEditor.selection.currentPage;
      console.log('Current page:', currentPage);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DocumentEditorContainerComponent
        ref={docxViewerRef}
        id="container"
        height="100%"
        serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        enableToolbar={true}
        documentEditorSettings={{
          enableReadOnly: true,
          isReadOnly: true,
          enablePropertiesPane: false,
        }}
        toolbarItems={toolbarItems}
        style={{
          height: "100%",
          width: "100%",
          maxHeight: "100%",
          overflow: "auto",
        }}
        created={onCreated}
        showPropertiesPane={false}
        contentChange={handlePageChange}
      />
    </div>
  );
});

export default DocxEditor;