// src/store/selectedItemSlice.js
import { createSlice } from "@reduxjs/toolkit";

const selectedItemSlice = createSlice({
  name: "selectedItem",
  initialState: {},
  reducers: {
    setSelectedItem: (state, action) => {
      return action.payload;
    },

    refreshSelectedItem: (state, action) => {
      const { uuid, payload } = action.payload;
      if (state.uuid === uuid) {
        // Update state with payload while preserving uuid
        return { ...payload, uuid: state.uuid };
      }
      return state;
    },
    updateSelectedItemContent: (state, action) => {
      // No need to check UUID since we're just updating the content
      // of the currently selected item
      return { ...state, ...action.payload };
    },
  },
});

export const { setSelectedItem, refreshSelectedItem, updateSelectedItemContent } =
  selectedItemSlice.actions;
export default selectedItemSlice.reducer;
