import React, { useState, useEffect, useCallback } from 'react';
import { ControlledBubbleMenu } from "mui-tiptap"
import { Box, TextField, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import { api_external } from '../../store';
import env from '../../config';
import styled from '@emotion/styled';
import { DOMSerializer } from 'prosemirror-model'


const SlimButton = styled(Button)(({ theme }) => ({
  padding: '1px 10px',
}));

const EditingInterface = ({ editor, onClose, onGenerate, onAccept, isLoading, oldText, newText }) => {
  const [editText, setEditText] = useState('');

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        background: 'white',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        zIndex: 1000,
        transform: 'translateY(-100%)',
        marginTop: '-8px'
      }}
    >
      <IconButton
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: '-10px',
          top: '-10px',
          padding: '4px',
          backgroundColor: 'white',
          borderRadius: '50%',
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
          '&:hover': {
            backgroundColor: '#f5f5f5'
          }
        }}
      >
        <CloseIcon sx={{ fontSize: 16 }} />
      </IconButton>

      <Box sx={{ position: 'relative', width: '500px' }}>
        {newText ? (
          <>
            <Box
              sx={{
                backgroundColor: '#E5E4E2',
                color: '#666666',
                padding: '8px',
                marginBottom: '8px',
                borderRadius: '4px',
                fontFamily: 'inherit',
                fontSize: '0.875rem',
                '& ul': {
                  listStyleType: 'disc',
                  paddingLeft: '20px'
                },
                '& ol': {
                  listStyleType: 'decimal',
                  paddingLeft: '20px'
                }
              }}
              dangerouslySetInnerHTML={{ __html: oldText }}
            />
            <Box
              sx={{
                backgroundColor: '#e8f5e9',
                color: '#2e7d32',
                padding: '8px',
                borderRadius: '4px',
                fontFamily: 'inherit',
                fontSize: '0.875rem',
                '& ul': {
                  listStyleType: 'disc',
                  paddingLeft: '20px'
                },
                '& ol': {
                  listStyleType: 'decimal',
                  paddingLeft: '20px'
                }
              }}
              dangerouslySetInnerHTML={{ __html: newText }}
            />
          </>
        ) : (
          <TextField
            multiline
            fullWidth
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            variant="outlined"
            autoFocus
            placeholder="Editing instructions..."
            InputProps={{
              style: {
                fontSize: '0.875rem',
              },
            }}
            style={{
              '& .MuiInputBase-input': {
                minHeight: '50px',
                maxHeight: '200px',
                overflowY: 'auto',
              }
            }}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mt: 1 }}>
        {isLoading ? (
          <>
            <SlimButton
              variant="outlined"
              onClick={onClose}
              size="small"
            >
              Cancel
            </SlimButton>
            <CircularProgress size={20} />
          </>
        ) : newText ? (
          <SlimButton
            onClick={onAccept}
            variant="contained"
            size="small"
          >
            Accept
          </SlimButton>
        ) : (
          <SlimButton
            onClick={() => onGenerate(editText)}
            variant="contained"
            size="small"
          >
            Generate
          </SlimButton>
        )}
      </Box>
    </Box>
  );
};

export const EditBubbleMenu = ({ editor, displayItem }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [shouldShow, setShouldShow] = useState(false);
  const [newText, setNewText] = useState('');
  const [bubblePosition, setBubblePosition] = useState(null);

  const getSelectionPosition = useCallback(() => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return null;

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const editorElement = editor.view.dom.closest('.MuiTiptap-RichTextContent-root');
    const editorRect = editorElement.getBoundingClientRect();

    return {
      x: rect.left + (rect.width / 2),
      y: rect.top,
      editorTop: editorRect.top,
      editorBottom: editorRect.bottom
    };
  }, [editor]);

  const isSelectionVisible = useCallback(() => {
    if (!editor) return false;

    const pos = getSelectionPosition();
    if (!pos) return false;

    return (
      pos.y >= pos.editorTop &&
      pos.y <= pos.editorBottom &&
      pos.y >= 0 &&
      pos.y <= window.innerHeight
    );
  }, [editor, getSelectionPosition]);

  const handleScroll = useCallback(() => {
    setTimeout(() => {
      const { from, to } = editor.state.selection;
      const hasSelection = from !== to;
      const isVisible = isSelectionVisible();

      if (hasSelection && isVisible) {
        const pos = getSelectionPosition();
        setBubblePosition(pos);
        setShouldShow(true);
      } else {
        setShouldShow(false);
      }
    }, 50);
  }, [editor, isSelectionVisible, getSelectionPosition]);

  const handleClose = useCallback(() => {
    setIsEditing(false);
    setIsLoading(false);
    setSelectedText('');
    setNewText('');
  }, []);

  const handleGenerate = useCallback(async (editText) => {
    setIsLoading(true);
    try {
      const requestData = {
        text: selectedText,
        edit_prompt: editText,
        context: editor.getHTML()
      };
      const response = await api_external.post(
        env.salesPubAPI + "/edit",
        requestData
      );
      setNewText(response.data.edited_text[0].content);
    } catch (error) {
      console.error('Error generating edit:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedText, editor]);

  const handleAccept = useCallback(() => {
    if (!editor || !newText) return;

    const { from, to } = editor.state.selection;
    editor.chain().focus()
      .deleteRange({ from, to })
      .insertContent(newText)
      .run();
    handleClose();
  }, [editor, newText, handleClose]);

  const handleEditClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!editor || displayItem !== 'answer') return;

    const { from, to } = editor.state.selection;

    // Get the selected content as a slice
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the fragment to HTML using DOMSerializer
    const div = document.createElement('div');
    const fragment = DOMSerializer
      .fromSchema(editor.schema)
      .serializeFragment(selectedFragment.content);

    div.appendChild(fragment);
    const selectedHTML = div.innerHTML;

    setSelectedText(selectedHTML);
    setIsEditing(true);
    setShouldShow(false);
  }, [editor, displayItem]);

  useEffect(() => {
    if (!editor || displayItem !== 'answer') {
      setShouldShow(false);
      return;
    }

    const updateShouldShow = () => {
      const { from, to } = editor.state.selection;
      const hasSelection = from !== to;
      const isVisible = isSelectionVisible();

      if (hasSelection && isVisible && !isEditing) {
        const pos = getSelectionPosition();
        setBubblePosition(pos);
        setShouldShow(true);
      } else {
        setShouldShow(false);
      }
    };

    const editorElement = editor.view.dom.closest('.MuiTiptap-RichTextContent-root');

    window.addEventListener('scroll', handleScroll, true);
    editorElement?.addEventListener('scroll', handleScroll, true);
    editor.on('selectionUpdate', updateShouldShow);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      editorElement?.removeEventListener('scroll', handleScroll, true);
      editor.off('selectionUpdate', updateShouldShow);
    };
  }, [editor, displayItem, isEditing, handleScroll, isSelectionVisible, getSelectionPosition]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  if (!editor || displayItem !== 'answer') {
    return null;
  }

  return (
    <>
      <ControlledBubbleMenu
        editor={editor}
        open={shouldShow || isEditing}
        anchorMode="cursor"
        tippyOptions={{
          placement: 'top',
          getReferenceClientRect: () => ({
            width: 0,
            height: 0,
            left: bubblePosition?.x ?? 0,
            top: bubblePosition?.y ?? 0,
            right: bubblePosition?.x ?? 0,
            bottom: bubblePosition?.y ?? 0,
          })
        }}
      >
        {isEditing ? (
          <EditingInterface
            editor={editor}
            onClose={handleClose}
            onGenerate={handleGenerate}
            onAccept={handleAccept}
            isLoading={isLoading}
            oldText={selectedText}
            newText={newText}
          />
        ) : (
          <button
            onClick={handleEditClick}
            className="bubble-menu-button"
            type="button"
            style={{
              padding: '0.5rem 1rem',
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          >
            Edit
          </button>
        )}
      </ControlledBubbleMenu>
    </>
  );
};