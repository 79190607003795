import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Alert,
  Skeleton,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { api } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { selectOpportunities, updateOpportunityLocally, fetchOpportunities, selectOpportunitiesLoading, selectOpportunitiesError } from "../reduxstore/opportunitiesSlice";
import CustomTableToolbar from "../Components/rfp/CustomTableToolbar";
import StatusCell from "../Components/rfp/StatusCell";
import ChatWidget from "../Components/ChatWidget";
import AnimatedChatFab from "../Components/AnimatedFab";
import AddIcon from "@mui/icons-material/Add";

const ConfidenceScore = ({ score }) => {
  const color = '#55828b'; // Single color for all filled dots

  return (
    <div className="flex items-center h-full">
      <div className="flex space-x-1">
        {[1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${index <= score ? 'scale-110' : 'scale-100'
              }`}
            style={{
              backgroundColor: index <= score ? color : '#E0E0E0',
              boxShadow: index <= score ? '0 0 4px rgba(0,0,0,0.2)' : 'none'
            }}
          />
        ))}
      </div>
    </div>
  );
};

const LoadingSkeleton = () => (
  <Box
    sx={{
      height: 'calc(100vh - 200px)',
      width: '100%',
      backgroundColor: 'background.paper',
      borderRadius: 2,
      boxShadow: 1,
      p: 2
    }}
  >
    {[...Array(5)].map((_, index) => (
      <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={300} height={40} />
        <Skeleton variant="rectangular" width={200} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={150} height={40} />
        <Skeleton variant="rectangular" width={50} height={40} />
      </Box>
    ))}
  </Box>
);

const Opportunities = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunities = useSelector(selectOpportunities);
  const loading = useSelector(selectOpportunitiesLoading);
  const error = useSelector(selectOpportunitiesError);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.userStatus.user);
  const isChatOpen = useSelector(state => state.chatWidget.isOpen);
  const [sortModel, setSortModel] = useState(() => {
    const sortParam = searchParams.get('sort');
    if (sortParam) {
      try {
        return JSON.parse(decodeURIComponent(sortParam));
      } catch (e) {
        console.error('Error parsing sort parameters:', e);
        return [{ field: 'publication_date', sort: 'desc' }];
      }
    }
    return [{ field: 'publication_date', sort: 'desc' }];
  });

  // Initialize state from URL parameters
  const [paginationModel, setPaginationModel] = useState({
    pageSize: Number(searchParams.get('pageSize')) || 10,
    page: Number(searchParams.get('page')) || 0,
  });

  // Filter states with URL persistence
  const [lastViewedId, setLastViewedId] = useState(location.state?.lastViewedId || null);
  const [filterModel, setFilterModel] = useState({
    quickFilterValues: searchParams.get('search')?.split(',').filter(Boolean) || [],
    items: [],
  });
  const [selectedCategories, setSelectedCategories] = useState(
    searchParams.get('categories')?.split(',').filter(Boolean) || []
  );
  const [matchRelevanceScore, setMatchRelevanceScore] = useState(
    Number(searchParams.get('matchScore')) || 0
  );
  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get('status') || 'all'
  );
  const [filteredRows, setFilteredRows] = useState([]);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams({
      page: paginationModel.page.toString(),
      pageSize: paginationModel.pageSize.toString(),
      categories: selectedCategories.join(','),
      matchScore: matchRelevanceScore.toString(),
      status: selectedStatus,
      sort: encodeURIComponent(JSON.stringify(sortModel)),
    });

    if (filterModel.quickFilterValues.length > 0) {
      params.set('search', filterModel.quickFilterValues.join(','));
    }

    setSearchParams(params);
  }, [
    paginationModel,
    selectedCategories,
    matchRelevanceScore,
    selectedStatus,
    filterModel.quickFilterValues,
    setSearchParams,
    sortModel
  ]);

  // Update lastViewedId from location state
  useEffect(() => {
    if (location.state?.lastViewedId) {
      setLastViewedId(location.state.lastViewedId);
    }
  }, [location.state]);

  // Apply filters to data
  useEffect(() => {
    const baseData = opportunities
      .filter(opp => opp.scoring.score >= matchRelevanceScore)
      .filter(opp =>
        selectedStatus === 'all' ||
        (opp.status !== undefined ? opp.status.some(s => s.userId === user._id && s.status === selectedStatus) : false) ||
        opp.favoritedBy?.some(fav => fav.userId === user._id && selectedStatus === 'favorite')
      )
      .filter(opp => {
        if (selectedCategories.length === 0) return false;
        return opp.category && selectedCategories.includes(opp.category);
      });

    const processedData = baseData.map(opp => ({
      ...opp,
      matchScore: opp.scoring.score,
      location: `${opp.state}, ${opp.country}`,
      issuer: opp.overview?.issuer
    }));

    const searchText = filterModel.quickFilterValues.join(' ').toLowerCase();
    const filteredData = !searchText ? processedData : processedData.filter(row =>
      (row.title?.toLowerCase().includes(searchText) || '') ||
      (row.issuer?.toLowerCase().includes(searchText) || '')
    );

    setFilteredRows(filteredData);
  }, [
    opportunities,
    matchRelevanceScore,
    selectedCategories,
    selectedStatus,
    filterModel.quickFilterValues,
    user._id
  ]);

  const columns = [
    {
      field: 'matchScore',
      headerName: 'Match Relevance',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return <ConfidenceScore score={params.row.matchScore} />
      },
      sortComparator: (v1, v2) => v1 - v2,
      filterable: true,
      hideable: true,
      disableColumnMenu: true,
      filterOperators: [
        {
          label: 'equals',
          value: 'equals',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => (
            <FormControl fullWidth size="small">
              <Select
                value={item.value || ''}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}
              >
                {[2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          label: 'greater than',
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value > filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => (
            <FormControl fullWidth size="small">
              <Select
                value={item.value || ''}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}
              >
                {[1, 2, 3, 4].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          label: 'less than',
          value: 'lessThan',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value < filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => (
            <FormControl fullWidth size="small">
              <Select
                value={item.value || ''}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}
              >
                {[2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        }
      ]
    },

    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 350,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      minWidth: 50,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
    },
    {
      field: 'issuer',
      headerName: 'Issuer',
      flex: 1,
      minWidth: 50,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
    },
    {
      field: 'publication_date',
      headerName: 'Published Date',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    {
      field: 'deadline',
      headerName: 'Due Date',
      width: 120,
      valueFormatter: (params) => {
        return new Date(params).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Shortlist',
      width: 120,
      sortable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%', height: '100%', marginLeft: 1 }}>
          <StatusCell
            params={params}
            onStatusChange={handleStatusChange}
            userId={user._id}

          />
        </Box>

      )
    }
  ];

  // Initialize categories from opportunities
  useEffect(() => {
    if (!searchParams.get('categories') && opportunities.length > 0) {
      const allCategories = [...new Set(opportunities.map(opp => opp.category))].sort();
      setSelectedCategories(allCategories);
    }
  }, [opportunities]); // Only run when opportunities changes

  // Fetch opportunities if empty
  useEffect(() => {
    if (opportunities.length === 0) {
      dispatch(fetchOpportunities());
    }
  }, [dispatch, opportunities.length]);

  const handleRowClick = async (params) => {
    try {
      if (params.row.status === 'new') {
        dispatch(updateOpportunityLocally({
          rfx_id: params.row.rfx_id,
          changes: { status: 'viewed' }
        }));

        await api.put(`/capture/opportunities/${params.row.rfx_id}`, {
          status: 'viewed'
        });
      }

      navigate(
        `/opportunities/${params.row.rfx_id}?${searchParams.toString()}`,
        { state: { user } }
      );
    } catch (err) {
      console.error("Error updating viewed status:", err);
    }
  };

  const handleStatusChange = async (rfx_id, newStatus) => {
    try {
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          status: [
            ...(opportunities.find(o => o.rfx_id === rfx_id)?.status || [])
              .filter(s => s.userId !== user._id),
            {
              userId: user._id,
              status: newStatus,
              updatedAt: new Date().toISOString()
            }
          ]
        }
      }));

      await api.put(`/capture/opportunities/${rfx_id}`, {
        status: newStatus
      });
    } catch (err) {
      console.error("Error updating status:", err);
      // Revert on error
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          status: opportunities.find(o => o.rfx_id === rfx_id)?.status || []
        }
      }));
    }
  };

  if (loading) return <LoadingSkeleton />;
  if (error) return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 3,
        mt: 4
      }}>
        <Typography variant="pageHeading">Opportunities</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={() => navigate("/new-opportunity")}
        >
          Add
        </Button>
      </Box>

      <Grid container>
        <Grid item xs={12}>
          <Box sx={{
            height: 'calc(100vh - 150px)',
            width: '100%',
            '& .MuiDataGrid-root': {
              border: 'none',
              backgroundColor: 'background.paper',
              borderRadius: 2,
              boxShadow: 1
            },
            '& .viewed-row': {
              backgroundColor: 'rgba(234, 242, 244, 0.8)',
              '&:hover': {
                backgroundColor: 'rgba(234, 242, 244, 0.8)',
              }
            },
            '& .last-viewed-row': {
              borderTop: '1px solid',
              borderRight: '1.8px solid',
              borderLeft: '1.8px solid',
              borderBottom: '1.5px solid',
              borderColor: 'primary.main'
            }
          }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              getRowId={(row) => row.rfx_id}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 25, 50]}
              filterMode="client"
              onFilterModelChange={setFilterModel}
              filterModel={filterModel}
              disableRowSelectionOnClick
              onRowClick={handleRowClick}
              rowCount={filteredRows.length}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                setSortModel(newSortModel);
              }}
              // initialState={{
              //   sorting: {
              //     sortModel: [{ field: 'publication_date', sort: 'desc' }],
              //   },
              // }}
              getRowClassName={(params) => {
                const isViewed = params.row.status?.some(view => view.userId === user._id);
                const isLastViewed = params.row.rfx_id === lastViewedId;
                return `${isViewed ? 'viewed-row' : ''} ${isLastViewed ? 'last-viewed-row' : ''}`.trim();
              }}
              slots={{
                toolbar: (props) => (
                  <CustomTableToolbar
                    {...props}
                    opportunities={opportunities}
                    selectedCategories={selectedCategories}
                    onCategoryChange={(value) => {
                      setSelectedCategories(value);
                      setSearchParams(new URLSearchParams({
                        categories: value.join(',')
                      }));
                    }}
                    selectedStatus={selectedStatus}
                    onStatusChange={setSelectedStatus}
                    matchRelevanceScore={matchRelevanceScore}
                    onMatchRelevanceChange={setMatchRelevanceScore}
                  />
                )
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 300 },
                },
              }}
              sx={{
                border: 'none',
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {isChatOpen && (
        <ChatWidget />
      )}
      {user?.featureAccess?.generation && (
        <AnimatedChatFab
        />
      )}
    </Box>
  );
};

export default Opportunities;