import React, { useRef, useImperativeHandle, forwardRef, useEffect } from "react";
import {
  PdfViewerComponent, Inject, Toolbar,
  Navigation, LinkAnnotation,
  BookmarkView, ThumbnailView, TextSelection,
  Annotation, TextSearch, FormFields, FormDesigner, Magnification,
} from '@syncfusion/ej2-react-pdfviewer';
import '@syncfusion/ej2-react-pdfviewer/styles/material.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import "@syncfusion/ej2-pdfviewer/styles/material.css";
import "./scss/PDFViewer.scss";

const toolbarItems = {
  showTooltip: true,
  toolbarItems: [
    'PageNavigationTool',
    // 'SelectionTool',
    'MagnificationTool',
    'SearchOption',
    'DownloadOption'
  ],
};



const PDFViewer = forwardRef(
  ({ fileUrl, initialPage, showToolbar = true, defaultScale = 1.1, fileName, onDownload, onDelete, refreshUrl }, ref) => {
    const pdfViewerRef = useRef(null);

    const formToolbarItems = {
      showTooltip: true,
      toolbarItems: [
        {
          id: 'filename',
          text: fileName || 'Document',
          tooltipText: fileName || 'Document', // Full filename shown on hover
          align: 'Left',
          type: 'Label',
          // cssClass: 'e-pv-filename-label',
          template: `<div style="padding: 0 10px; font-weight: 500; max-width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
    ${(fileName || 'Document').length > 75 ?
              (fileName || 'Document').substring(0, 100) + '...' :
              (fileName || 'Document')}
  </div>`
        },
        'MagnificationTool',
        'UndoRedoTool',
        // 'AnnotationEditTool',
        // 'FormDesignerEditTool',
        // 'FreeTextAnnotationOption',
        // 'InkAnnotationOption',
        // 'ShapeAnnotationOption',
        // 'StampAnnotation',
        // 'SignatureOption',
        {
          prefixIcon: 'e-icons e-error-treeview',
          id: 'deleteForm',
          text: 'Detach File',
          tooltipText: 'Detach File',
          ariaLabel: 'Detach File',
          align: 'Left',
          cssClass: 'e-pv-button custom-tooltip',
        },
        'SearchOption',
        'DownloadOption',


        // {
        //   prefixIcon: 'e-icons e-save',
        //   id: 'saveForm',
        //   text: 'Save',
        //   tooltipText: 'Save',

        //   cssClass: 'e-pv-button custom-tooltip',

        // },
      ],
      // annotationToolbarItems:
      //   ['HighlightTool',
      //     'UnderlineTool',
      //     'StrikethroughTool',
      //     'ColorEditTool',
      //     'OpacityEditTool',
      //     'AnnotationDeleteTool',
      //     'StampAnnotationTool',
      //     'HandWrittenSignatureTool',
      //     'InkAnnotationTool',
      //     'ShapeTool',
      //     'CalibrateTool',
      //     'StrokeColorEditTool',
      //     'ThicknessEditTool',
      //     'FreeTextAnnotationTool',
      //     'FontFamilyAnnotationTool',
      //     'FontSizeAnnotationTool',
      //     'FontStylesAnnotationTool',
      //     'FontAlignAnnotationTool',
      //     'FontColorAnnotationTool',
      //     'CommentPanelTool'],
      // formDesignerToolbarItems:
      //   ['TextboxTool',
      //     'PasswordTool',
      //     'CheckBoxTool',
      //     'RadioButtonTool',
      //     'DropdownTool',
      //     'ListboxTool',
      //     'DrawSignatureTool',
      //     'DeleteTool']
    }

    const toolbar = showToolbar ? toolbarItems : formToolbarItems;

    const handleDocumentLoaded = (args) => {
      if (pdfViewerRef.current && initialPage > 1) {
        // Access the navigation module through the viewer instance
        pdfViewerRef.current.navigationModule?.goToPage(initialPage);
      }
    };

    useImperativeHandle(ref, () => ({
      getCurrentPage: () => pdfViewerRef.current?.currentPage || 1,
      goToPage: (pageNumber) => {
        if (pdfViewerRef.current?.navigationModule) {
          pdfViewerRef.current.navigationModule.goToPage(pageNumber);
        }
      },
      viewer: pdfViewerRef.current
    }));

    const handlePageChange = (args) => {
      if (pdfViewerRef.current) {
        pdfViewerRef.current.currentPage = args.currentPageNumber;
      }
    };

    useEffect(() => {
      const validateUrl = async () => {
        try {
          const response = await fetch(fileUrl);
          if (response.status === 400) {
            await refreshUrl();
          }
        } catch (error) {
          console.error("Error validating URL:", error);
        }
      };

      validateUrl();
    }, [fileUrl, refreshUrl]);

    const handleToolbarClick = (args) => {
      if (!args.item) {
        return;
      }
      if (args.item.id === 'deleteForm') {
        if (onDelete) {
          onDelete();
        }

      } else if (args.item.id === 'saveForm') {
        console.log('submitForm');
        if (pdfViewerRef.current) {
          // call api to save pdf
          const response = api.post('/syncfusion/pdf/save', {
            file: pdfViewerRef.current.export('pdf')
          });
          // update file on gcp
          // saveUrl call api

          // Get form field data

        }
      }
    };



    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div
          style={{
            // height: showToolbar || fileName ? "100%" : "100%",
            height: "100%",
            overflow: "auto",
            width: "100%",
          }}
        >
          <PdfViewerComponent
            ref={pdfViewerRef}
            id="container"
            documentPath={fileUrl}
            resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
            saveUrl="https://localhost:3080/api/syncfusion/pdf/save"
            toolbarSettings={toolbar}
            pageNumber={initialPage}
            enableBookmark={showToolbar}
            enableThumbnail={showToolbar}
            enableNavigationToolbar={showToolbar}
            style={{ height: '100%', width: '100%' }}
            pageChange={handlePageChange}
            documentLoad={handleDocumentLoaded}
            zoomMode="Zoom"
            zoomValue={0}
            toolbarClick={handleToolbarClick}
          >
            <Inject
              services={[
                Toolbar,
                Navigation,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                TextSelection,
                // TextSearch,
                // FormFields,
                // FormDesigner,
                Magnification
              ]}
            />
          </PdfViewerComponent>
        </div>
      </div>
    );
  }
);

export default PDFViewer;