import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import "./App.scss";
import SettingsPage from "./Pages/Settings";
import Archives from "./Pages/Archives";
import AddArchives from "./Pages/AddArchive";
import ArchiveFiles from "./Pages/ArchiveFiles";
import PasswordChange from "./Pages/PasswordChange";
import Layout from "./Layout";
import ResetPassword from "./Pages/ResetPassword";
import ForgotPassword from "./Pages/ForgotPassword";
import AuthPage from "./Components/AuthComponent";
import RFPDashboard from "./Pages/RFPDashboard";
import RFPLoader from "./Pages/RFPLoader";
import CreateRFXProject from "./Pages/CreateRFXProject";
import RFPEditor from "./Pages/RFPEditor";
import { Provider } from "react-redux";
import { store } from "./reduxstore";
import Opportunities from "./Pages/RFxOpportunities";
import OpportunityDetail from "./Pages/RFxOpportunityDetail";
import { AuthProvider } from "./providers/AuthProvider";
import { useAuth } from "./contexts/AuthContext";
import CreateOpportunity from "./Pages/CreateOpportunity";

const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const currentPath = window.location.pathname;
  const searchParams = window.location.search;
  const redirectUrl = `/login?redirect=${encodeURIComponent(
    currentPath + searchParams
  )}`;

  return isLoggedIn ? <Layout>{children}</Layout> : <Navigate to={redirectUrl} />;
};

const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/signup" element={<AuthPage />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/passchange" element={<RequireAuth><PasswordChange /></RequireAuth>} />
            <Route path="/rfp-dashboard" element={<RequireAuth><RFPDashboard /></RequireAuth>} />
            <Route path="/rfp-loader" element={<RequireAuth><RFPLoader /></RequireAuth>} />
            <Route path="/new-rfp" element={<RequireAuth><CreateRFXProject /></RequireAuth>} />
            <Route path="/rfp-editor" element={<RequireAuth><RFPEditor /></RequireAuth>} />
            <Route path="/settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
            <Route path="/ask-archives" element={<RequireAuth><Archives /></RequireAuth>} />
            <Route path="/archive-add" element={<RequireAuth><AddArchives /></RequireAuth>} />
            <Route path="/archive-files" element={<RequireAuth><ArchiveFiles /></RequireAuth>} />
            <Route path="/rfx-opportunities" element={<RequireAuth><Opportunities /></RequireAuth>} />
            <Route path="/opportunities/:id" element={<RequireAuth><OpportunityDetail /></RequireAuth>} />
            <Route path="/new-opportunity" element={<RequireAuth><CreateOpportunity /></RequireAuth>} />
          </Routes>
        </Router>
      </AuthProvider>
    </Provider>
  );
};

export default App;