// src/store/itemsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../store";
import { setSelectedItem } from "./selectedItemSlice";

export const reorderItems = createAsyncThunk(
  "items/reorderItems",
  async (params, { getState, dispatch }) => {
    const { newOrder, projectId } = params;
    await api.put("/project/updateDraft", {
      projectId,
      sections: newOrder,
    });
    return newOrder;
  }
);

export const deleteItemAndUpdate = createAsyncThunk(
  "items/deleteItemAndUpdate",
  async (params, { getState, dispatch }) => {
    const { itemToDelete, projectId } = params;
    const state = getState();
    const currentItems = state.items;
    const selectedItem = state.selectedItem;

    const updatedItems = currentItems.filter(
      (item) => item.uuid !== itemToDelete.uuid
    );

    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    // If the deleted item was selected, select the next item (or the last item if it was the last one)
    if (selectedItem.uuid === itemToDelete.uuid) {
      const deletedIndex = currentItems.findIndex(
        (item) => item.uuid === itemToDelete.uuid
      );
      const newSelectedItem =
        updatedItems[deletedIndex] ||
        updatedItems[updatedItems.length - 1] ||
        null;
      if (newSelectedItem) {
        dispatch(setSelectedItem(newSelectedItem));
      }
    }

    return updatedItems;
  }
);

// like updateItem create a new method that updates the updated_config in the item and also in db
export const addUpdateConfig = createAsyncThunk(
  "items/addUpdateConfig",
  async ({ projectId }, { getState, dispatch }) => {
    // here call the api to get items
    const response = await api.get("/project/getProjectById", {
      params: { projectId },
    });
    const items = response.data.project?.rfx_response?.documents || [];

    const getDefaultConfig = (item) => ({
      title: item.title,
      description: item.description,
      type: item.type,
      requirements: item.requirements,
      sources: item.sources
    });

    const updateConfigForItem = (item) => ({
      ...item,
      updated_config: item.updated_config || getDefaultConfig(item)
    });

    const updatedItems = items.map(item => {
      const updatedItem = updateConfigForItem(item);

      if (updatedItem.sections) {
        updatedItem.sections = updatedItem.sections.map(section => {
          const updatedSection = updateConfigForItem(section);

          if (updatedSection.subsections) {
            updatedSection.subsections = updatedSection.subsections.map(updateConfigForItem);
          }

          return updatedSection;
        });
      }

      return updatedItem;
    });
    // update the updated_config in the item and also in db
    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });
    return updatedItems;
  }
);

export const updateItem = createAsyncThunk(
  "items/updateItem",
  async ({ uuid, key, value, projectId, version }, { getState, dispatch }) => {
    const state = getState();
    const currentItems = state.items;
    const selectedItemBeforeUpdate = state.selectedItem;
    // Find the item to update
    let itemToUpdate = currentItems.find((item) => item.uuid === uuid);
    if (!itemToUpdate) {
      // look for the item in sections
      for (let i = 0; i < currentItems.length; i++) {
        const section = currentItems[i].sections.find((item) => item.uuid === uuid);
        // also check subsections
        if (!section) {
          for (const section of currentItems[i].sections) {
            if (section.subsections) {
              const subSection = section.subsections.find(item => item.uuid === uuid);
              if (subSection) {
                itemToUpdate = subSection;
                break;
              }
            }
          }
        }
        if (section) {
          itemToUpdate = section;
          break;
        }
      }
      if (!itemToUpdate) {
        throw new Error("Item not found");
      }
    }

    // Create the updated item
    let updatedValue;
    if (version !== undefined && Array.isArray(itemToUpdate[key])) {
      // If version is defined and the field is an array, update the specific index
      updatedValue = [...itemToUpdate[key]];
      updatedValue[version] = value;
    } else {
      // If version is undefined or the field is not an array, update directly
      updatedValue = value;
    }

    const updatedItem = { ...itemToUpdate, [key]: updatedValue };
    // Create a new array with the updated item, considering both documents and sections
    const updatedItems = currentItems.map((item) => {
      if (item.uuid === uuid) {
        // If the item is a top-level document
        return updatedItem;
      } else if (item.sections) {
        // If the item is a document with sections
        const updatedSections = item.sections.map((section) => {
          if (section.uuid === uuid) {
            return updatedItem;
          } else if (section.subsections) {
            // Handle subsections
            const updatedSubsections = section.subsections.map((subsection) =>
              subsection.uuid === uuid ? updatedItem : subsection
            );
            return { ...section, subsections: updatedSubsections };
          }
          return section;
        });
        console.log("updatedSections: ", updatedSections);
        return { ...item, sections: updatedSections };
      }
      return item;
    });
    // Update the draft in the backend
    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    // Update the selectedItem if it's the item being updated
    const currentSelectedItem = getState().selectedItem;
    if (selectedItemBeforeUpdate.uuid === currentSelectedItem.uuid &&
      selectedItemBeforeUpdate.uuid === uuid) {
      await dispatch(setSelectedItem(updatedItem));
    }

    return updatedItems;
  }
);

export const updateConfig = createAsyncThunk(
  "items/updateConfig",
  async ({ uuid, config, projectId }, { getState, dispatch }) => {
    const state = getState();
    const currentItems = state.items;
    const selectedItemBeforeUpdate = state.selectedItem;
    // Find the item to update
    let itemToUpdate = currentItems.find((item) => item.uuid === uuid);
    if (!itemToUpdate) {
      // look for the item in sections
      for (let i = 0; i < currentItems.length; i++) {
        const section = currentItems[i].sections.find((item) => item.uuid === uuid);
        // also check subsections
        if (!section) {
          for (const section of currentItems[i].sections) {
            if (section.subsections) {
              const subSection = section.subsections.find(item => item.uuid === uuid);
              if (subSection) {
                itemToUpdate = subSection;
                break;
              }
            }
          }
        }
        if (section) {
          itemToUpdate = section;
          break;
        }
      }
      if (!itemToUpdate) {
        throw new Error("Item not found");
      }
    }

    const updatedItem = { ...itemToUpdate, ...config };
    // Create a new array with the updated item, considering both documents and sections
    const updatedItems = currentItems.map((item) => {
      if (item.uuid === uuid) {
        // If the item is a top-level document
        return updatedItem;
      } else if (item.sections) {
        // If the item is a document with sections
        const updatedSections = item.sections.map((section) => {
          if (section.uuid === uuid) {
            return updatedItem;
          } else if (section.subsections) {
            // Handle subsections
            const updatedSubsections = section.subsections.map((subsection) =>
              subsection.uuid === uuid ? updatedItem : subsection
            );
            return { ...section, subsections: updatedSubsections };
          }
          return section;
        });
        return { ...item, sections: updatedSections };
      }
      return item;
    });
    // Update the draft in the backend
    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    // Update the selectedItem if it's the item being updated
    const currentSelectedItem = getState().selectedItem;
    if (selectedItemBeforeUpdate.uuid === currentSelectedItem.uuid &&
      selectedItemBeforeUpdate.uuid === uuid) {
      await dispatch(setSelectedItem(updatedItem));
    }

    return updatedItems;
  }
);

export const addItemAndUpdate = createAsyncThunk(
  "items/addItemAndUpdate",
  async ({ item, parentUuid, projectId }, { getState, dispatch }) => {
    const state = getState();
    const currentItems = state.items;

    // Add the item to the state
    dispatch(addItem({ item, parentUuid }));

    // Get the updated items after adding the new item
    const updatedItems = getState().items;

    // Update the draft in the backend
    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    return updatedItems;
  }
);

const itemsSlice = createSlice({
  name: "items",
  initialState: [],
  reducers: {
    setItems: (state, action) => {
      return action.payload;
    },
    addItem: (state, action) => {
      const { item, parentUuid } = action.payload;
      if (!parentUuid) {
        // If no parentUuid is provided, add the item to the top level
        state.push(item);
      } else {
        // If parentUuid is provided, find the parent item and add to its sections/subsections
        const parentItem = state.find(i => i.uuid === parentUuid);
        if (parentItem) {
          // For top level documents, add to sections
          parentItem.sections.push(item);

        } else {
          // Search for parent at section level since not found at top level
          const sections = state.flatMap(doc => doc.sections || []);
          const sectionParent = sections.find(section => section.uuid === parentUuid);
          if (sectionParent) {
            if (!sectionParent.subsections) {
              sectionParent.subsections = [];
            }
            sectionParent.subsections.push(item);
          } else {
            console.error(`Parent item with uuid ${parentUuid} not found at top or section level`);
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reorderItems.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(reorderItems.rejected, (state, action) => {
        console.error("Failed to reorder items:", action.error);
      })
      .addCase(deleteItemAndUpdate.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(deleteItemAndUpdate.rejected, (state, action) => {
        console.error("Failed to delete item and update:", action.error);
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateItem.rejected, (state, action) => {
        console.error("Failed to update item:", action.error);
      });
  },
});

export const { addItem, setItems } = itemsSlice.actions;
export default itemsSlice.reducer;
