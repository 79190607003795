import React from 'react';
import { Button, Box } from "@mui/material";

const viewOptions = [
  { value: "configuration", label: "Config" },
  { value: "requirements", label: "Guide" },
  { value: "sources", label: "Sources" }
];

const ViewToggleButtons = ({ view, handleViewChange, selectedItem }) => (
  <Box sx={{ width: "60%", height: "3rem", display: "flex", gap: 0.5, height: "60%", ml: 1 }}>
    {viewOptions.map(({ value, label }) => (
      <Button
        key={value}
        variant={view === value ? "outlined" : "text"}
        size="small"
        onClick={(e) => handleViewChange(e, value)}
        color="inherit"
        disabled={
          (value === "requirements" && (!selectedItem?.answer && selectedItem?.type !== "Form")) ||
          (value === "sources" && !selectedItem?.answer)
        }
        sx={{
          flex: 1, // Make all buttons equal sized
          px: 1,
        }}
      >
        {label}
      </Button>
    ))}
  </Box>
);

export default ViewToggleButtons;