import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  LinkBubbleMenu,
  RichTextEditor,
  TableBubbleMenu,
  insertImages,
  FontSize,
  HeadingWithAnchor,
  LinkBubbleMenuHandler,
  ResizableImage,
  TableImproved,
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuControlsContainer,
  MenuDivider,
  MenuButton,
  MenuSelectTextAlign,
  MenuButtonAddTable,
  MenuSelectHeading,
  MenuButtonRemoveFormatting,
  MenuButtonUndo,
  MenuButtonRedo,
} from "mui-tiptap";
import { ListItem } from "@tiptap/extension-list-item";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Color } from "@tiptap/extension-color";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { FontFamily } from "@tiptap/extension-font-family";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Highlight } from "@tiptap/extension-highlight";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Link } from "@tiptap/extension-link";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Strike } from "@tiptap/extension-strike";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { Text } from "@tiptap/extension-text";
import { TextAlign } from "@tiptap/extension-text-align";
import { TextStyle } from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";
import { updateItem } from "../reduxstore/itemsSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateItemStatus } from "../reduxstore/itemsStatusSlice";
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { EditBubbleMenu } from "./rfp/AIEditorTipTap";

const REGEX_TODO = /\[TODO:[\s\S]*?\]/g;

const TodoHighlight = Extension.create({
  name: "todoHighlight",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("todoHighlight"),
        props: {
          decorations: (state) => {
            const { doc } = state;
            const decorations = [];

            // Track TODO state
            let todoStart = -1;
            let isCollectingTodo = false;

            doc.descendants((node, pos) => {
              if (!node.isText) return;

              const text = node.text;

              if (!isCollectingTodo) {
                // Look for new TODO start
                const todoStartIndex = text.indexOf('[TODO:');
                if (todoStartIndex !== -1) {
                  todoStart = pos + todoStartIndex;
                  isCollectingTodo = true;

                  // Check if TODO ends in the same node
                  const todoEndIndex = text.indexOf(']', todoStartIndex);
                  if (todoEndIndex !== -1) {
                    decorations.push(
                      Decoration.inline(todoStart, pos + todoEndIndex + 1, {
                        class: "todo-highlight"
                      })
                    );
                    isCollectingTodo = false;
                    todoStart = -1;
                  }
                }
              } else {
                // Look for TODO end
                const todoEndIndex = text.indexOf(']');
                if (todoEndIndex !== -1) {
                  decorations.push(
                    Decoration.inline(todoStart, pos + todoEndIndex + 1, {
                      class: "todo-highlight"
                    })
                  );
                  isCollectingTodo = false;
                  todoStart = -1;
                }
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});

const EditBubbleMenuExtension = Extension.create({
  name: 'editBubbleMenu',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('editBubbleMenu'),
        props: {
          handleDOMEvents: {
            mouseup: (view, event) => {
              const selection = window.getSelection();
              if (selection && !selection.isCollapsed) {
                return true;
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

const CustomSubscript = Subscript.extend({
  excludes: "superscript",
});

const CustomSuperscript = Superscript.extend({
  excludes: "subscript",
});

const CustomListItem = ListItem.extend({
  renderHTML({ HTMLAttributes }) {
    return ["li", { ...HTMLAttributes, style: "margin-left: 1em;" }, 0];
  },
});

const StyledRichTextEditor = React.forwardRef((props, ref) => (
  <RichTextEditor {...props} ref={ref} />
));

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: "custom-paragraph",
        renderHTML: (attributes) => {
          return {
            class: `custom-paragraph`,
          };
        },
      },
    };
  },
});

const CustomHeading = HeadingWithAnchor.configure({
  levels: [1, 2, 3, 4, 5, 6],
}).extend({
  addAttributes() {
    const original = this.parent?.();
    return {
      ...original,
      class: {
        default: (level) => `custom-h${level}`,
        renderHTML: (attributes) => ({
          ...attributes,
          class: `custom-h${attributes.level}`.trim(),
        }),
      },
    };
  },
});

const CustomStyledRichTextEditor = styled(StyledRichTextEditor)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "95%",
  "& .MuiTiptap-RichTextContent-root": {
    flex: 1,
    height: "inherit",
    overflowY: "auto",
    margin: "0.5rem",
    "& .custom-paragraph": {
      fontSize: "0.875rem !important",
      lineHeight: 1.5,
    },
    "& .custom-h1": {
      "& [data-node-view-content]": { fontSize: "1rem" },
    },
    "& .custom-h2": {
      "& [data-node-view-content]": { fontSize: "0.95rem" },
    },
    "& .custom-h3": {
      "& [data-node-view-content]": { fontSize: "0.9rem" },
    },
    "& .custom-h4": {
      "& [data-node-view-content]": { fontSize: "0.89rem" },
    },
    "& .custom-h5, & .custom-h6": {
      "& [data-node-view-content]": { fontSize: "0.875rem" },
    },

    "& .custom-heading": {
      fontWeight: "bold",
      marginBottom: "0.5em",
    },
  },
  "& .todo-highlight": {
    backgroundColor: "#d4e2e5",
    padding: "2px",
    borderRadius: "2px",
  },
});

const TipTapEditor = ({
  rteRef,
  projectId,
  archiveIds,
  refreshItems,
  showToolbar,
  displayItem,
  readOnly,
}) => {
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const contentRef = useRef("");
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const [versions, setVersions] = useState([]);
  const selectedItemRef = useRef(selectedItem);

  const isEditorDisabled = useMemo(() => {
    const status = itemsStatus.find((item) => item.uuid === selectedItem?.uuid);
    return status?.isGenerating || false;
  }, [itemsStatus, selectedItem]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    // Early return if selectedItem is null or undefined
    if (!selectedItem) {
      setVersions([]);
      setCurrentVersionIndex(0);
      setContent("");
      contentRef.current = "";

      if (rteRef?.current?.editor) {
        queueMicrotask(() => {
          rteRef.current.editor.commands.setContent("");
        });
      }
      return;
    }

    // Proceed if it's the first render or if the UUID has changed
    if (!isFirstRender.current && selectedItemRef.current?.uuid === selectedItem.uuid) {
      if (displayItem === "answer" && selectedItem[displayItem]?.length === versions.length) {
        return;
      }
    }

    // Update refs
    isFirstRender.current = false;
    selectedItemRef.current = selectedItem;

    if (displayItem === "requirements") {
      const content = selectedItem.updated_config?.requirements || "";
      setVersions([content]);
      setCurrentVersionIndex(0);
      setContent(content);
      contentRef.current = content;

      if (rteRef.current?.editor) {
        queueMicrotask(() => {
          rteRef.current?.editor.commands.setContent(content);
        });
      }
    } else if (selectedItem[displayItem]) {
      const newVersions = Array.isArray(selectedItem[displayItem])
        ? selectedItem[displayItem]
        : [selectedItem[displayItem]];

      setVersions(newVersions);
      const latestVersionIndex = newVersions.length - 1;
      setCurrentVersionIndex(latestVersionIndex);

      // Set the content to the latest version
      const latestContent = newVersions[latestVersionIndex];
      setContent(latestContent);
      contentRef.current = latestContent;

      if (rteRef.current?.editor) {
        queueMicrotask(() => {
          rteRef.current?.editor.commands.setContent(latestContent);
        });
      }
    } else {
      setVersions([]);
      setCurrentVersionIndex(0);
      setContent("");
      contentRef.current = "";

      if (rteRef.current?.editor) {
        queueMicrotask(() => {
          rteRef.current.editor.commands.setContent("");
        });
      }
    }
  }, [selectedItem, displayItem]);

  const handleChange = useCallback(
    ({ editor }) => {
      contentRef.current = editor.getHTML();
      if (displayItem === "answer") {
        const newTodoCount = countTodos(contentRef.current);
        dispatch(
          updateItemStatus({
            uuid: selectedItem.uuid,
            todoCount: newTodoCount,
            version: currentVersionIndex,
          })
        );
      }
    },
    [selectedItem]
  );

  const countTodos = (text) => {
    // const regex = /\[TODO:\s*([^\]]+)\]/g;
    const regex = REGEX_TODO;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  };

  const handleBlur = useCallback(() => {
    if (selectedItem && contentRef.current !== selectedItem?.[displayItem]) {
      dispatch(
        updateItem({
          uuid: selectedItem.uuid,
          key: displayItem === 'requirements' ? 'updated_config' : displayItem,
          value: displayItem === 'requirements' ? {
            ...selectedItem.updated_config,
            requirements: contentRef.current
          } : contentRef.current,
          projectId,
          version: currentVersionIndex,
        })
      );
    }
  }, [selectedItem, dispatch, currentVersionIndex]);

  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (editor) {
      editor.on("blur", handleBlur);
    }
    return () => {
      if (editor) {
        editor.off("blur", handleBlur);
      }
    };
  }, [handleBlur]);

  const extensions = useMemo(
    () => [
      TableImproved.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      BulletList,
      CodeBlock,
      Document,
      HardBreak,
      CustomListItem,
      OrderedList,
      CustomSubscript,
      CustomSuperscript,
      Text,
      Bold,
      Blockquote,
      Code,
      Italic,
      Underline,
      Strike,
      CustomLinkExtension.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
      }),
      LinkBubbleMenuHandler,
      Gapcursor,
      TextAlign.configure({
        types: ["heading", "paragraph", "image"],
      }),
      CustomParagraph,
      CustomHeading,
      TextStyle,
      Color,
      FontFamily,
      FontSize,
      Highlight.configure({ multicolor: true }),
      HorizontalRule,
      ResizableImage,
      Dropcursor,
      History,
      TodoHighlight,
      EditBubbleMenuExtension,
    ],
    []
  );

  const handleNewImageFiles = useCallback((files, insertPosition) => {
    if (!rteRef.current?.editor) {
      return;
    }

    const attributesForImageFiles = files.map((file) => ({
      src: URL.createObjectURL(file),
      alt: file.name,
    }));

    insertImages({
      images: attributesForImageFiles,
      editor: rteRef.current.editor,
      insertPosition,
    });
  }, []);

  const handleDrop = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = Array.from(event.dataTransfer.files).filter((file) => {
        const mimeType = (file.type || "").toLowerCase();
        return mimeType.startsWith("image/");
      });

      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);
        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = Array.from(event.clipboardData.files).filter(
        (file) => {
          const mimeType = (file.type || "").toLowerCase();
          return mimeType.startsWith("image/");
        }
      );

      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const renderControls = useCallback(
    () => (
      <MenuControlsContainer>
        <MenuSelectFontFamily
          options={[
            { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
            { label: "Cursive", value: "cursive" },
            { label: "Monospace", value: "monospace" },
            { label: "Serif", value: "serif" },
          ]}
          sx={{ fontSize: "0.875rem" }}
        />
        <MenuDivider />
        <MenuSelectHeading sx={{ fontSize: "0.875rem" }} />
        <MenuDivider />
        <MenuSelectFontSize />
        <MenuDivider />
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonStrikethrough />
        <MenuButtonUnderline />
        {/* <MenuButtonSubscript />
        <MenuButtonSuperscript /> */}
        {/* <MenuDivider />
        <MenuButtonTextColor />
        <MenuButtonHighlightColor /> */}
        {/* <MenuDivider />
        <MenuButtonEditLink /> */}
        <MenuDivider />
        <MenuSelectTextAlign />
        <MenuDivider />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        {/* <MenuDivider />
        <MenuButtonBlockquote /> */}
        <MenuDivider />
        {/* <MenuButtonHorizontalRule /> */}
        <MenuButtonAddTable />
        <MenuDivider />
        <MenuButtonRemoveFormatting />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
        {/* <MenuButton
          tooltipLabel="Regenerate Section"
          IconComponent={AutoModeRoundedIcon}
          onClick={isEditorDisabled ? undefined : handleRegenerate}
          sx={{
            cursor: isEditorDisabled ? "auto" : "pointer",
          }}
          disabled={isEditorDisabled}
        /> */}
      </MenuControlsContainer>
    ),
    [isEditorDisabled]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        overflowY: "hidden",
      }}
    >
      <CustomStyledRichTextEditor
        key={selectedItem?.uuid} // Add this line to force re-render
        ref={rteRef}
        extensions={extensions}
        content={content}
        editable={!isEditorDisabled && !readOnly}
        onUpdate={handleChange}
        editorProps={{
          handleDrop: handleDrop,
          handlePaste: handlePaste,
          attributes: {
            style: `pointer-events: ${isEditorDisabled ? "none" : "auto"
              }; opacity: ${isEditorDisabled ? 0.7 : 1};`,
          },
        }}
        renderControls={showToolbar ? renderControls : null}
        RichTextFieldProps={{
          variant: "outlined",
          MenuBarProps: {
            hide: false,
          },
        }}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
            {displayItem === "answer" && (
              <EditBubbleMenu editor={rteRef.current?.editor} displayItem={displayItem} />
            )}

          </>
        )}
      </CustomStyledRichTextEditor>
    </Box>
  );
};
export default TipTapEditor;
export { REGEX_TODO };
