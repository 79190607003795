import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  ListItem,
  ListItemText,
  Divider,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import { api_external } from "../../store";
import env from '../../config';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../reduxstore/itemsSlice';

const SourceSearch = ({ archive_ids, onCancel, rowData, selectedItem, projectId }) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    setLoading(true);
    setHasSearched(true);
    try {
      const requestData = {
        archive_ids: archive_ids,
        query: searchQuery,
      };
      const response = await api_external.post(
        env.salesPubAPI + "/search-sources",
        requestData
      );
      setSearchResults(response.data.search_results);
    } catch (error) {
      console.error('Error searching sources:', error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSourceExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCancel = () => {
    setSearchQuery('');
    setSearchResults([]);
    setHasSearched(false);
    onCancel();
  };

  const getFileNameFromPath = (path) => {
    if (path !== undefined) {
      const foundItem = rowData.find((item) => item.id === path);
      return foundItem ? foundItem.name : path;
    }
    return path;
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, mb: 2, p: 2 }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Search sources..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                <IconButton size="small" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </>
            ),
          }}
        />
        <IconButton size="small" onClick={handleCancel}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </Box>

      {loading ? (
        <ListItem>
          <ListItemText primary="Searching..." />
        </ListItem>
      ) : searchResults.length > 0 ? (
        searchResults.map((source, index) => (
          <Accordion
            key={index}
            expanded={expanded === source.context}
            onChange={handleSourceExpansion(source.context)}
            disableGutters
            sx={{
              display: 'block',
              px: 1
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="small" />}
              aria-controls={`panel-${source.uuid}-content`}
              id={`panel-${source.context}-header`}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                  <Typography variant="primaryText" noWrap>
                    {source.title}
                  </Typography>
                  <Tooltip title={getFileNameFromPath(source.self_link)} arrow>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        lineHeight: 1.2,
                        display: 'block',
                        textOverflow: 'wrap',
                      }}
                    >
                      {getFileNameFromPath(source.self_link)}
                    </Typography>
                  </Tooltip>
                </Box>
                {selectedItem?.updated_config?.sources?.some(s => s.context === source.context) ? (
                  <IconButton size="small" sx={{ flexShrink: 0 }} disabled>
                    <CheckCircleRoundedIcon fontSize="small" color="success" />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    title="Add source"
                    sx={{ flexShrink: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      source.uuid = Math.max(0, ...(selectedItem.updated_config?.sources || []).map(s => s.uuid)) + 1;
                      const transformedSource = {
                        ...source,
                        uuid: source.uuid,
                        extract: source.content,
                        source_path: source.self_link,
                        metadata: {
                          context: source.context,
                          self_link: source.self_link,
                          title: source.title,
                        }
                      };
                      dispatch(updateItem({
                        projectId: projectId,
                        uuid: selectedItem.uuid,
                        key: 'updated_config',
                        value: {
                          ...selectedItem.updated_config,
                          sources: [...(selectedItem.updated_config?.sources || []), transformedSource]
                        }
                      }));
                    }}
                  >
                    <AddCircleOutlineRoundedIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ overflowX: 'hidden', width: '100%', maxWidth: '100%' }}>
              <Typography
                variant="secondaryText"
                sx={{
                  color: 'text.secondary',
                  fontSize: '0.875rem',
                  fontStyle: 'italic'
                }}
                dangerouslySetInnerHTML={{
                  __html: source.context,
                }}
              />
              <Box sx={{ my: 2 }}>
                <Divider />
              </Box>
              <Typography
                variant="primaryText"
                className="typography-content"
                dangerouslySetInnerHTML={{
                  __html: source.content,
                }}
                sx={{ overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', width: '100%' }}
              />
            </AccordionDetails>
          </Accordion>
        ))
      ) : hasSearched ? (
        <ListItem>
          <ListItemText primary="No results found" />
        </ListItem>
      ) : null}
    </Box>
  );
};

export default SourceSearch;