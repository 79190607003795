import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Modal, Paper, Tooltip, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { v4 as uuidv4 } from 'uuid';
import { addItemAndUpdate, addItem, setItems } from '../../reduxstore/itemsSlice';
import {
  addItemStatus,
  updateItemStatus,
} from "../../reduxstore/itemsStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { api_external } from "../../store";
import env from "../../config";

const AddSectionModal = ({
  isOpen,
  onClose,
  currentSection,
  projectId,
  archiveIds,
  refreshItems
}) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items);
  const [newSectionTitle, setNewSectionTitle] = useState(currentSection?.title || "");
  const [newSectionDescription, setNewSectionDescription] = useState(currentSection?.description || "");
  const [subsections, setSubsections] = useState(currentSection?.subsections || []);
  const [isFormSection, setIsFormSection] = useState(currentSection?.type === "Form");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isNewSection, setIsNewSection] = useState(!currentSection?.uuid);

  useEffect(() => {
    setIsNewSection(!currentSection?.uuid);
    setNewSectionTitle(currentSection?.title || "");
    setNewSectionDescription(currentSection?.description || "");
    setSubsections(currentSection?.subsections || []);
    setIsFormSection(currentSection?.type === "Form");
  }, [currentSection]);

  const handleGenerateNewSection = async () => {
    // Validation
    if (!newSectionTitle?.trim() || !newSectionDescription?.trim()) {
      setSnackbarMessage("Section title and description are required.");
      setSnackbarOpen(true);
      return;
    }

    if (!isFormSection && subsections.length > 0) {
      const hasEmptySubsection = subsections.some(
        (subsection) => !subsection.title.trim() || !subsection.description.trim()
      );
      if (hasEmptySubsection) {
        setSnackbarMessage("All subsection fields must be filled.");
        setSnackbarOpen(true);
        return;
      }
    }

    let newNodes = [];

    try {
      // Create new node structure
      if (isNewSection) {
        const sectionId = uuidv4();
        newNodes.push({
          uuid: sectionId,
          title: newSectionTitle,
          description: newSectionDescription,
          type: isFormSection ? "Form" : "Free Text"
        });
      }

      // if (isNewSection && isFormSection) {
      //   await dispatch(addItemAndUpdate({
      //     item: newNodes[0],
      //     parentUuid: currentSection?.parentUuid, // optional, omit for top-level items
      //     projectId: projectId
      //   }));
      //   handleClose();
      //   return;
      // }
      handleClose();

      // Map subsections if any
      if (subsections.length > 0) {
        if (newNodes.length === 0) {
          // iterate over subsections without uuid and add them to newNodes
          newNodes.push(...subsections.filter(subsection => !subsection.uuid).map(subsection => ({
            ...subsection,
            uuid: uuidv4(),
            type: subsection.isForm ? "Form" : "Free Text",
          })));
        }
        else {
          // If newNode has other properties, add subsections as an array
          newNodes[0].subsections = subsections.filter(subsection => !subsection.uuid).map(subsection => ({
            ...subsection,
            uuid: uuidv4(),
            type: subsection.isForm ? "Form" : "Free Text",
          }));
        }
      }

      // Add to Redux with loading state
      newNodes.forEach(node => {
        dispatch(addItem({ item: node, parentUuid: isNewSection ? currentSection?.parentUuid : currentSection?.uuid }));
        dispatch(addItemStatus({ uuid: node.uuid, isGenerating: true }));
        if (node.subsections) {
          node.subsections.forEach(subsection => {
            dispatch(addItemStatus({ uuid: subsection.uuid, isGenerating: true }));
          });
        }
      });


      // Prepare API request data for content generation
      const requestData = {
        project_id: projectId,
        archive_ids: archiveIds,
        parent_id: isNewSection ? currentSection?.parentUuid : currentSection?.uuid,
        new_nodes: newNodes
      };

      // Make API call
      await api_external.post(
        `${env.salesPubAPI}/generate-new-node-response`,
        requestData
      );


      // Update loading state after API call completes
      newNodes.forEach(node => {
        dispatch(updateItemStatus({ uuid: node.uuid, isGenerating: false }));
        if (node.subsections) {
          node.subsections.forEach(subsection => {
            dispatch(updateItemStatus({ uuid: subsection.uuid, isGenerating: false }));
            refreshItems(subsection.uuid, true);
          });
        }
        refreshItems(node.uuid, true);
      });


      handleClose();

    } catch (error) {
      console.error("Error generating section:", error);
      // Remove item from Redux store if API call fails
      newNodes.forEach(node => {
        dispatch(setItems(items.filter((item) => item.uuid !== node.uuid)));
      });

      setSnackbarMessage("Failed to generate content. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSubsectionChange = (index, field, value) => {
    const updatedSubsections = [...subsections];
    updatedSubsections[index][field] = value;
    setSubsections(updatedSubsections);
  };

  const addSubsection = () => {
    setSubsections([...subsections, { title: "", description: "", type: "Free Text" }]);
  };

  const handleClose = () => {
    setNewSectionTitle("");
    setNewSectionDescription("");
    setSubsections([]);
    setIsFormSection(false);
    setSnackbarOpen(false);
    setSnackbarMessage("");
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper className="w-full max-w-4xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 max-h-[80vh] overflow-y-auto">
        <Typography id="modal-modal-title" variant="sectionTitle">
          Add Section
        </Typography>

        {snackbarOpen && (
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
            <ErrorIcon className="mr-2 text-red-500" />
            <Typography className="text-red-500">{snackbarMessage}</Typography>
          </Box>
        )}

        <TextField
          label="Section title"
          sx={{ mb: 2, mt: 2 }}
          value={newSectionTitle}
          onChange={(e) => {
            if (!currentSection?.title) {
              setNewSectionTitle(e.target.value);
            }
          }}
          fullWidth
          required
          className="mb-4"
          inputProps={{ maxLength: 50 }}
          disabled={!!currentSection?.title}
        />

        <TextField
          label="Section description"
          value={newSectionDescription}
          onChange={(e) => {
            if (!currentSection?.description) {
              setNewSectionDescription(e.target.value);
            }
          }}
          fullWidth
          required
          multiline
          minRows={3}
          className="mb-4"
          inputProps={{ maxLength: 150 }}
          disabled={!!currentSection?.description}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isFormSection}
              onChange={(e) => setIsFormSection(e.target.checked)}
              disabled={!isNewSection}
            />
          }
          label="Form section"
          className="mb-4"
        />

        <Box className="mb-4">
          {currentSection?.uuid && !currentSection?.subsections?.length && (
            <Box className="flex items-center mb-4 p-2 bg-yellow-50 border border-yellow-200 rounded">
              <ErrorOutlineIcon className="mr-2 text-yellow-600" />
              <Typography className="text-yellow-700">
                Warning: Adding subsections will regenerate the entire section content
              </Typography>
            </Box>
          )}
          <Box className="flex justify-between items-center mb-2">
            <Box className="flex items-center">
              <Typography variant="subtitle1">Subsections</Typography>
            </Box>
            <IconButton
              onClick={addSubsection}
              disabled={isFormSection}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>

          {subsections.map((subsection, index) => (
            <Box key={index} className="flex gap-4 mb-4">
              <TextField
                label="Title"
                value={subsection.title}
                onChange={(e) => !currentSection?.subsections?.[index]?.title && handleSubsectionChange(index, "title", e.target.value)}
                className="w-1/3"
                inputProps={{ maxLength: 50 }}
                disabled={!!currentSection?.subsections?.[index]?.title}
              />
              <TextField
                label="Description"
                value={subsection.description}
                onChange={(e) => !currentSection?.subsections?.[index]?.description && handleSubsectionChange(index, "description", e.target.value)}
                className="w-1/2"
                inputProps={{ maxLength: 100 }}
                disabled={!!currentSection?.subsections?.[index]?.description}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subsection.isForm}
                    onChange={(e) => handleSubsectionChange(index, "isForm", e.target.checked)}
                    disabled={!!currentSection?.subsections?.[index]?.type}
                  />
                }
                label="Form"
              />
            </Box>
          ))}
        </Box>

        <Button
          variant="contained"
          onClick={handleGenerateNewSection}
          className="w-full mt-4"
        >
          Generate
        </Button>
      </Paper>
    </Modal>
  );
};

export default AddSectionModal;