import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useSelector, useDispatch } from "react-redux";
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { deleteItemAndUpdate, setItems } from "../../reduxstore/itemsSlice";
import AddSectionModal from "./AddSectionModal";
import DeleteConfirmationDialog from "./DeleteSection";

function FacebookCircularProgress(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.grey[200],
          ...theme.applyStyles("dark", {
            color: theme.palette.grey[800],
          }),
        })}
        size={18}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: "#55828b",
          animationDuration: "550ms",
          position: "absolute",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            color: "#308fe8",
          }),
        })}
        size={18}
        thickness={5}
        {...props}
      />
    </Box>
  );
}

const EditableItem = React.memo(
  ({
    parentId,
    item,
    index,
    onItemClick,
    isLoading,
    isError,
    isSelected,
    todoCount,
    handleAddSection,
    handleAddSubsection,
    handleDelete,
  }) => {
    const itemRef = useRef(null);
    const [contextMenu, setContextMenu] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleContextMenu = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setContextMenu(
        contextMenu === null
          ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
          : null,
      );
    };

    const handleCloseContextMenu = () => {
      setContextMenu(null);
    };

    const ItemIcon = ({ parentId, type, item }) => {
      const iconStyle = { color: "#666666", fontSize: "0.875rem" };
      if (parentId) {
        // Parent ID is not empty
        if (type === "Form") {
          return <AttachFileRoundedIcon sx={iconStyle} />;
        } else {
          // For sections with subsections, show folder icon
          if (item.subsections && item.subsections.length > 0) {
            return <InsertDriveFileOutlinedIcon sx={iconStyle} />;
          } else {
            // For sections without subsections, show document icon
            return <SubjectRoundedIcon sx={iconStyle} />;
          }
        }
      } else {
        // Parent ID is empty
        if (type === "Form") {
          return <AttachFileRoundedIcon sx={iconStyle} />;
        } else {
          // Assuming "Free Text" type when not "Form"
          return <InsertDriveFileOutlinedIcon sx={iconStyle} />;
        }
      }
    };

    return (
      <ListItem
        ref={itemRef}
        onClick={() => onItemClick(item, parentId)}
        onContextMenu={handleContextMenu}
        sx={{
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          backgroundColor: isSelected ? "rgba(0, 0, 0, 0.06)" : "none",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "8px",
        }}
        title={item.title}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.7rem",
            alignItems: "center",
            flexGrow: 1,
            minWidth: 0,
            px: 1
          }}
        >
          <ItemIcon parentId={parentId} item={item} type={item.type} />

          <ListItemText
            primary={item.title}
            primaryTypographyProps={{
              sx: {
                fontSize: "0.875rem",
                lineHeight: 1.2,
                color: "#666666",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0.5rem",
              },
            }}
            sx={{
              cursor: "pointer",
              flexGrow: 1,
              minWidth: 0,
            }}
          />
          {!isLoading && !isError && todoCount > 0 && (
            <Badge
              title="TODOs"
              badgeContent={todoCount}
              sx={{
                "& .MuiBadge-badge": {
                  color: "#55828b",
                  border: "0.1rem solid #55828b",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "16px",
                  height: "16px",
                  fontSize: "0.6rem",
                  padding: "0 4px",
                  marginLeft: "8px"
                }
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
          {isLoading && <FacebookCircularProgress />}
          {isError && !isLoading && (
            <ErrorRoundedIcon
              sx={{ marginRight: "2px", color: "red", fontSize: "16px" }}
            />
          )}
        </Box>
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {[
            item.type === 'Form' ? (
              <MenuItem key="disabled" sx={{ px: 2.5, fontSize: "0.875rem" }} disabled>
                Add Section
              </MenuItem>
            ) : !item.subsections && item.sections ? (
              <MenuItem key="addSection" sx={{ px: 2.5, fontSize: "0.875rem" }} onClick={() => {
                handleAddSection(item);
                handleCloseContextMenu();
              }}>
                Add Section
              </MenuItem>
            ) : !item.sections && item.subsections ? (
              <MenuItem key="addSubsection" sx={{ px: 2.5, fontSize: "0.875rem" }} onClick={() => {
                handleAddSubsection(item, parentId);
                handleCloseContextMenu();
              }}>
                Add Subsection
              </MenuItem>
            ) : (
              <MenuItem key="disabledNoSections" sx={{ px: 2.5, fontSize: "0.875rem" }} disabled>
                Add Section
              </MenuItem>
            ),
            <MenuItem key="delete" sx={{ px: 2.5, fontSize: "0.875rem" }} onClick={() => {
              setDeleteDialogOpen(true);
              handleCloseContextMenu();
            }}>
              Delete
            </MenuItem>
          ]}
        </Menu>
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          selectedItem={item}
          handleDelete={handleDelete}
        />
      </ListItem>
    );
  }
);

const RFPStructure = ({
  onItemClick,
  projectId,
  archiveIds,
  refreshItems,
}) => {
  const listRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const items = useSelector((state) => state.items);
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);
  const [currentSection, setCurrentSection] = useState(null);
  const dispatch = useDispatch();

  const handleDocumentClick = (doc) => {
    onItemClick(doc);
  };

  const handleDelete = useCallback(
    async (itemToDelete) => {
      const originalItems = [...items];
      let updatedItems = items.map(item => {
        if (itemToDelete.uuid === item.uuid) {
          return null;
        }
        if (item.sections) {
          const updatedSections = item.sections.map(section => {
            if (itemToDelete.uuid === section.uuid) {
              return null;
            }
            if (section.subsections) {
              const updatedSubsections = section.subsections.filter(subsection =>
                subsection.uuid !== itemToDelete.uuid
              );
              if (updatedSubsections.length !== section.subsections.length) {
                return {
                  ...section,
                  subsections: updatedSubsections
                };
              }
            }
            return section;
          }).filter(Boolean);

          if (updatedSections.length !== item.sections.length ||
            updatedSections.some((section, index) => section !== item.sections[index])) {
            return {
              ...item,
              sections: updatedSections
            };
          }
        }
        return item;
      }).filter(Boolean);

      dispatch(setItems(updatedItems));

      try {
        await dispatch(
          deleteItemAndUpdate({ itemToDelete, projectId })
        ).unwrap();
      } catch (error) {
        console.error("Error deleting item:", error);
        dispatch(setItems(originalItems));
      }
    },
    [items, dispatch, projectId]
  );

  const renderHeader = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingBottom: "1rem",
        borderBottom: "1px solid #ddd",
        height: "2rem",
        // mx: 2,
        marginTop: 2
      }}
    >
      <Typography
        variant="sectionHeading"
        sx={{
          color: "black",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexGrow: 1,
          flexShrink: 1,
          mx: 2,
          minWidth: 0, // This allows the text to shrink below its content size
        }}
      >
        Bid Packet
      </Typography>
    </Box>
  );

  const renderContent = () => {
    return (
      <List ref={listRef} sx={{ flexGrow: 1, overflowY: "auto", px: 2 }}>
        {items.map((doc, index) => {
          const renderItem = (item, parentId, depth = 0) => {
            const itemStatus = itemsStatus.find((status) => status.uuid === item.uuid) || {};
            return (
              <Box key={item.uuid}>
                <EditableItem
                  parentId={parentId}
                  item={item}
                  index={index}
                  onItemClick={() => handleDocumentClick(item)}
                  isLoading={itemStatus.isGenerating || false}
                  isError={itemStatus.isError || false}
                  isSelected={item.uuid === selectedItem?.uuid}
                  todoCount={itemStatus.todoCount}
                  handleAddSection={(item) => handleAddSection(item)}
                  handleAddSubsection={(item, parentId) => handleAddSubsection(item, parentId)}
                  handleDelete={handleDelete}
                />
                {item.sections && item.sections.length > 0 && (
                  <List sx={{ pl: 2 }}>
                    {item.sections.map((section) => section.tag ? null : renderItem(section, item.uuid, depth + 1))}
                  </List>
                )}
                {item.subsections && item.subsections.length > 0 && (
                  <List sx={{ pl: 4 }}>
                    {item.subsections.map((subsection) => renderItem(subsection, item.uuid, depth + 1))}
                  </List>
                )}
              </Box>
            );
          };
          return renderItem(doc, null);
        })}
      </List>
    );
  };

  const handleAddSection = (parentItem) => {
    setCurrentSection({
      parentUuid: parentItem.uuid
    });
    setIsModalOpen(true);
  };

  const handleAddSubsection = (item, parentId) => {
    item = {
      ...item,
      parentUuid: parentId
    };
    setCurrentSection(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderHeader()}
      {renderContent()}
      <AddSectionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        currentSection={currentSection}
        projectId={projectId}
        archiveIds={archiveIds}
        refreshItems={refreshItems}
      />
    </Box>
  );
};

export default RFPStructure;
