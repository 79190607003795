import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../store";

const initialState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  userArchiveIDs: [],
  archives: []
};

export const fetchUserData = createAsyncThunk(
  'userStatus/fetchUserData',
  async () => {
    const archiveIdsResponse = await api.get("/archives/getUserArchivesId");
    const idsString = archiveIdsResponse.data.archives
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");
    const archivesResponse = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );
    return {
      archiveIds: archiveIdsResponse.data.archives.map((item) => item.id),
      archives: archivesResponse.data
    };
  }
);

const userStatusSlice = createSlice({
  name: "userStatus",
  initialState,
  reducers: {
    setUserStatus: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;
    },
    setUserArchiveIDs: (state, action) => {
      state.userArchiveIDs = action.payload;
    },
    setArchives: (state, action) => {
      state.archives = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.error = null;
      state.userArchiveIDs = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.userArchiveIDs = action.payload.archiveIds;
        state.archives = action.payload.archives;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { setUserStatus, setUserArchiveIDs, setArchives, setLoading, setError, clearUser } = userStatusSlice.actions;

export default userStatusSlice.reducer;
