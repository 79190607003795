import React from 'react';
import { Paper, styled, Box } from '@mui/material';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { DocumentEditorContainerComponent, Toolbar } from "@syncfusion/ej2-react-documenteditor";
import PDFViewer from "./PDFViewer";
import { useSelector } from "react-redux";
import DocxEditorComponent from "./DocxEditorComponent";

const ViewerPanel = styled(Paper)(({ theme }) => ({
  width: "calc(80% - 8px)",
  minWidth: "calc(80% - 8px)",
  maxWidth: "calc(80% - 8px)",
  height: "100%",
  border: "1px solid #ddd",
  ...theme.typography.body2,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const DocumentViewerPanel = ({ tab, pdfViewerRef, lastPages, refreshUrl, docxViewerRef }) => {
  const projectFiles = useSelector((state) => state.projectFiles);

  if (
    projectFiles.currentFile &&
    projectFiles.currentFile.metadata.metadata.contentType === "application/pdf"
  ) {
    return (
      <ViewerPanel>
        {tab === "documents" && (
          <PDFViewer
            ref={pdfViewerRef}
            fileUrl={projectFiles.currentFile.uri}
            initialPage={lastPages[projectFiles.currentFile.uri] || 1}
            refreshUrl={refreshUrl}
          />
        )}
      </ViewerPanel>
    );
  } else {
    return (
      <ViewerPanel>
        <DocxEditorComponent
          fileUrl={projectFiles.currentFile.uri}
          initialPage={lastPages[projectFiles.currentFile.uri] || 1}
          ref={docxViewerRef}
        />
      </ViewerPanel>
    );
  }
};

export default DocumentViewerPanel;