import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box
} from "@mui/material";

const DeleteConfirmationDialog = ({ open, onClose, selectedItem, handleDelete }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="delete-dialog-title"
    aria-describedby="delete-dialog-description"
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle id="delete-dialog-title">
      Confirm Deletion
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="delete-dialog-description">
        This action cannot be undone.
        {selectedItem?.sections?.length > 0 && (
          <>
            <Typography variant="secondaryText" sx={{ mt: 2, mb: 1 }}>
              The following sections will be deleted:
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Typography>• {selectedItem.title}</Typography>
              {selectedItem.sections.map((section, index) => (
                <Box key={index} sx={{ pl: 2 }}>
                  <Typography>• {section.title}</Typography>
                  {section.subsections?.map((subsection, subIndex) => (
                    <Typography key={subIndex} sx={{ pl: 2 }}>
                      • {subsection.title}
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          </>
        )}
        {selectedItem?.subsections?.length > 0 && (
          <>
            <Typography variant="secondaryText" sx={{ mt: 2, mb: 1 }}>
              The following subsections will be deleted:
            </Typography>
            {selectedItem.subsections.map((subsection, index) => (
              <Box key={index} sx={{ pl: 2 }}>
                <Typography>• {subsection.title}</Typography>
              </Box>
            ))}
          </>
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleDelete(selectedItem);
          onClose();
        }}
        color="error"
        autoFocus
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteConfirmationDialog;