import { fetchUserData } from "./UserStatusSlice";

export const userStatusMiddleware = store => next => action => {
  // First, let the action go through and update the state
  const result = next(action);

  // Then check if it was the setUserStatus action and if a user was set
  if (action.type === 'userStatus/setUserStatus' && action.payload) {
    // Dispatch fetchUserData
    store.dispatch(fetchUserData());
  }

  return result;
};