import React, { useState, useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "../supabaseclient";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/xPub_Logo.png";
import { Grid, Column, InlineNotification } from "@carbon/react";
import "../Pages/Login.scss";
import ProfileCompletion from "./ProfileCompletion";
import { api, api_external, loginWithDataTokenAndProfile } from "../store";
import env from "../config";
import { useDispatch } from 'react-redux';
import { setUserStatus } from '../reduxstore/UserStatusSlice';
import { useAuth } from '../contexts/AuthContext';

const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onLogin } = useAuth();
  const [view, setView] = useState("sign_in");
  const [supabaseUser, setSupabaseUser] = useState(null);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [showProfileCompletion, setShowProfileCompletion] = useState(false);
  const [serverName, setServerName] = useState("");

  const customTheme = {
    default: {
      colors: {
        brand: "var(--xpub-accent-color-2)",
        brandAccent: "var(--xpub-accent-color-2)",
      },
    },
  };

  useEffect(() => {
    const serverName = window.location.origin;
    setServerName(serverName);

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "SIGNED_IN") {
          let res;
          try {
            res = await getUserAuthorization(session.user.email);
            if (res && res.data) {
              setSupabaseUser(session.user);
              setAccessToken(session.access_token);
              setUser(res.data.profile);
              await loginWithDataTokenAndProfile(session.access_token, res.data.profile);

              // call functions after login
              loginFuncCalls(res.data.profile);
              onLogin();

              const searchParams = new URLSearchParams(window.location.search);
              const redirectUrl = searchParams.get("redirect");

              if (res.data.profile.force_passchange) {
                navigate("/passchange", {
                  state: { profile: res.data.profile },
                });
              } else if (redirectUrl) {
                const decodedRedirect = decodeURIComponent(redirectUrl);
                const nestedParams = new URLSearchParams(decodedRedirect.split('?')[1] || '');
                const nestedRedirect = nestedParams.get('redirect');

                if (nestedRedirect) {
                  navigate(decodeURIComponent(nestedRedirect));
                } else {
                  navigate(decodedRedirect);
                }
              } else {
                if (res.data.profile.featureAccess?.vetting || res.data.profile.featureAccess?.generation) {
                  navigate("/rfp-dashboard");
                } else {
                  navigate("/rfx-opportunities");
                }
              }
            }
          } catch (err) {
            console.log(err);
            if (err.response?.status === 404) {
              setSupabaseUser(session.user);
              setAccessToken(session.access_token);
              setShowProfileCompletion(true);
            } else if (err.response?.status === 401) {
              setErrorMessage(
                err?.response?.data?.message || "Authorization error"
              );
            }
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [navigate, onLogin, dispatch]);

  const loginFuncCalls = async (user) => {
    firstLoginTasks(user);
  };

  const firstLoginTasks = async (user) => {
    if (
      user.is_first_login_pending ||
      user.is_first_login_pending === undefined
    ) {
      // set tour to true
      localStorage.removeItem("showDashboardTour");
      localStorage.removeItem("showEditorTour");
      try {
        // let response;
        // try {
        //   response = await api_external.post(
        //     env.salesPubAPI + "/trusted-source-gen",
        //     {
        //       industry: user.industry ? user.industry : "Healthcare",
        //       offerings: user.businessOfferings
        //         ? user.businessOfferings
        //         : "AI based prior authorization",
        //     }
        //   );
        //   //log the usage
        //   api.post("/user/logAsyncServiceCall", {
        //     projectId: "",
        //     service_tag: "generateTrustedSources",
        //     service_parent_id: null,
        //     service_filename: null,
        //     options: {
        //       service_progress_status: false,
        //       service_payload: {
        //         input: {
        //           industry: user.industry,
        //           offerings: user.businessOfferings,
        //         },
        //         output: response.data,
        //       },
        //     },
        //     logs: response.data.usage,
        //   });
        // } catch (err) {
        //   //log usage err
        //   api.post("/user/logAsyncServiceCall", {
        //     projectId: "",
        //     service_tag: "generateTrustedSources",
        //     service_parent_id: null,
        //     service_filename: null,
        //     options: {
        //       service_progress_status: false,
        //       service_error: true,
        //       service_error_msg: err?.message,
        //     },
        //   });
        // }

        // // create fact-check profile
        // //Check if users fact-check profile exists
        // const profile = await getFactcheckProfile();
        // // If not create one. If it does update it with these trusted sources
        // if (profile === null)
        //   await createFactcheckProfile(response.data.trustedSources);
        // else {
        //   await updateFactcheckProfile(
        //     profile.allow_list,
        //     response.data.trustedSources
        //   );
        // }
        // update user's profile to set is_first_login_pending to false
        updateUserProfile({ is_first_login_pending: false });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const updateUserProfile = async (updateObj) => {
    try {
      await api.put("/user/updateUserProfile", updateObj);
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const getUserAuthorization = async (email) => {
    try {
      setErrorMessage("");
      const res = await api.post("auth/signin", {
        email: email.trim(),
      });
      return res;
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || "Authorization error");
      throw err;
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
    if (newView === "sign_in") {
      navigate("/login");
    } else if (newView === "sign_up") {
      navigate("/signup");
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading message or spinner while processing
  }

  return (
    <Grid justify="center" align="center" fullWidth className="no-padding">
      <Column sm={0} md={0} lg={10} xl={10} className="left no-padding">
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img src={logo} width={300} alt="xPub" />
        </div>
      </Column>
      <Column
        sm={4}
        md={8}
        lg={6}
        xl={6}
        className="no-padding"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div style={{ height: "100vh" }}>
          <div className="login-container">
            {errorMessage && !showProfileCompletion && (
              <div style={{ margin: "0 1rem" }}>
                <InlineNotification
                  lowContrast
                  kind="error"
                  subtitle={errorMessage}
                  title="Login Error"
                />
              </div>
            )}
            <br></br>
            <div className="login-header">
              <span>
                {user
                  ? "Log in to xPub"
                  : showProfileCompletion
                    ? "Complete your profile"
                    : view === "sign_up"
                      ? "Create an account"
                      : "Log in to xPub"}
              </span>
            </div>
            {(!supabaseUser || errorMessage != "") &&
              !showProfileCompletion && (
                <div className="auth-full-width">
                  <Auth
                    supabaseClient={supabase}
                    providers={["google"]}
                    view={view}
                    redirectTo={
                      view === "sign_in"
                        ? `${serverName}/login`
                        : `${serverName}/signup`
                    }
                    showLinks={false}
                    appearance={{ theme: ThemeSupa, variables: customTheme }}
                    localization={{
                      variables: {
                        sign_up: {
                          social_provider_text: "Sign up with Google",
                          link_text: "Have an account? Sign In Now",
                        },
                        sign_in: {
                          social_provider_text: "Sign in with Google",
                        },
                      },
                    }}
                    onViewChange={(newView) => handleViewChange(newView)}
                  />
                  <br />
                  {view === "sign_up" && (
                    <button
                      className="supabase-auth-ui_ui-anchor c-dumjqv login-links"
                      onClick={() => {
                        navigate("/login");
                        setView("sign_in");
                      }}
                    >
                      Already have an account? Sign in
                    </button>
                  )}
                  {view === "sign_in" && (
                    <>
                      <button
                        className="supabase-auth-ui_ui-anchor c-dumjqv login-links"
                        onClick={() => {
                          navigate("/forgot-password");
                        }}
                      >
                        Forgot your password?
                      </button>
                      <br />
                      <button
                        className="supabase-auth-ui_ui-anchor c-dumjqv login-links"
                        onClick={() => {
                          navigate("/signup");
                          setView("sign_up");
                        }}
                      >
                        Don't have an account? Sign up
                      </button>
                    </>
                  )}
                </div>
              )}

            {supabaseUser && showProfileCompletion && (
              <ProfileCompletion
                supabaseUser={supabaseUser}
                accessToken={accessToken}
                onLogin={() => onLogin()}
                onSignup={(user) => loginFuncCalls(user)}
              />
            )}
          </div>
        </div>
      </Column>
    </Grid>
  );
};

export default AuthPage;
