import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Box, Typography, Divider, Popper, Paper, ClickAwayListener,
  IconButton, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails,
  ListItemIcon, ListItemSecondaryAction
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import { styled } from "@mui/material/styles";
import { api_external } from "../store";
import env from "../config";
import ReadOnlyTipTapEditor from './ReadOnlyTipTapEditor';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded'; import Loader from "react-loaders";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { api } from "../store";
import DOMPurify from 'dompurify';
import smallLogo from "../Assets/x_logo.png";
import { useSelector, useDispatch } from "react-redux";
import { setChatWidgetOpen } from "../reduxstore/ChatWidgetStatus";

const StyledContentEditable = styled('div')(({ theme }) => ({
  flex: 1,
  border: 'none',
  outline: 'none',
  padding: '4px',
  backgroundColor: 'transparent',
  minWidth: '50px',
  maxWidth: '100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  fontFamily: 'inherit',
  lineHeight: '1.5', // Added line spacing
  '&:empty:before': {
    content: 'attr(data-placeholder)',
    color: '#aaa',
  },
  '& .mention': {
    padding: '2px 10px',
    borderRadius: '4px',
    backgroundColor: '#e0f7fa',
    wordBreak: 'break-word !important',
  },
}));

const StyledInputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  padding: '8px',
  marginTop: 'auto',
  width: '100%',
  boxSizing: 'border-box',
}));

const StyledInput = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  minHeight: '36px',
  maxHeight: '150px',
  padding: '4px 8px',
  borderRadius: '8px',
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
  },
  overflow: 'hidden',
  fontSize: "0.875rem",
  backgroundColor: '#f5f5f5',
}));

const StyledAnswerContainer = styled('div')(({ theme }) => ({
  'p': {
    fontSize: '0.875rem',
  },
  '& .custom-superscript': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.6rem',
    fontWeight: '500',
    cursor: 'pointer',
    verticalAlign: 'top',
    textDecoration: 'none',
    marginLeft: '2px',
  },
}));

const StyledChatWidget = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '1rem',
  bottom: '5rem',
  width: '30%',
  height: '87%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgb(222, 233, 235, 0.85)',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  padding: '16px',
  boxSizing: 'border-box',
  zIndex: 1000,
  borderRadius: '8px',
  transition: 'all 0.3s ease-in-out',
  backdropFilter: 'blur(5px)',
}));

// Modify the scrollable container styling
const ScrollableContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  marginBottom: theme.spacing(2),
  marginLeft: `-${theme.spacing(0.5)}`,
  // paddingRight: theme.spacing(2), // Add padding to the right to prevent overlap
  marginRight: `-${theme.spacing(2)}`, // Negative margin to compensate for the added padding
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.2)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: '8px',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  marginRight: '1rem',
}));

const StepTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ChatWidget = ({ projectId }) => {
  const [query, setQuery] = useState('');
  const [answer, setAnswer] = useState('');
  const [sources, setSources] = useState([]);
  const inputRef = useRef(null);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [reference, setReference] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [lastQuery, setLastQuery] = useState('');
  const [lastReferenceType, setLastReferenceType] = useState(null);
  const [lastQueryCopy, setLastQueryCopy] = useState('');
  const [lastReferenceCopy, setLastReferenceCopy] = useState(null);
  const projectFiles = useSelector((state) => state.projectFiles.files);
  const dispatch = useDispatch();
  const userArchiveIDs = useSelector((state) => state.userStatus.userArchiveIDs);
  const archives = useSelector((state) => state.userStatus.archives);
  const refItems = [
    { name: 'Project File', type: '', icon: <InsertDriveFileIcon />, disabled: projectId === null || projectId === undefined },
    { name: 'Library', type: 'archive', icon: <ArchiveIcon /> },
  ];

  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const stripHtmlAndEntities = (html) => {
    // First, remove all HTML tags
    let stripped = html.replace(/<[^>]*>/g, '');

    // Then, decode HTML entities
    stripped = decodeHtmlEntities(stripped);

    // Finally, remove extra whitespace
    return stripped.replace(/\s+/g, ' ').trim();
  };

  const copyAnswer = () => {
    if (answer) {
      const plainTextAnswer = answer.map(item => {
        // Strip HTML tags and entities, then remove source indicators
        return stripHtmlAndEntities(item.content).replace(/\[\d+\]/g, '');
      }).join('\n\n');

      navigator.clipboard.writeText(plainTextAnswer).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    }
  };

  const stripReference = (input) => {
    if (reference) {
      const refPattern = new RegExp(`@${reference.name}\\s*`, 'i');
      return input.replace(refPattern, '').trim();
    }
    return input.trim();
  };

  const handleSubmit = async () => {
    const actualQuery = stripReference(query);
    if (actualQuery.trim() === "") {
      return;
    }
    try {
      setIsLoading(true);
      const queryText = inputRef.current.innerText;
      const cleanedQuery = stripReference(queryText);
      const requestData = {
        query: cleanedQuery,
        resources: reference ? [{ type: reference.type, ref: reference.ref }] : [],
      };
      reference && setLastReferenceType(reference.type);

      // Save copies of the current query and reference
      setLastQueryCopy(queryText);
      setLastReferenceCopy(reference);

      const response = await api_external.post(`${env.salesPubAPI}/ask-docs`, requestData);
      let answerArray = response.data.answer;

      // Process each item in the answer array
      const processedAnswer = answerArray.map(item => {
        if (item.type === 'text') {
          const parser = new DOMParser();
          const doc = parser.parseFromString(item.content, 'text/html');
          const pElement = doc.querySelector('p');

          if (pElement && pElement.textContent.trim().toLowerCase() === "i don't know") {
            return {
              ...item,
              content: `<p>I'm sorry, but I don't have enough information to answer that question confidently.</p>`
            };
          }
        }
        return item;
      });

      // Sanitize the HTML content in each answer item
      const sanitizedAnswer = processedAnswer.map(item => ({
        ...item,
        content: item.type === 'text' ? DOMPurify.sanitize(item.content) : item.content
      }));

      setAnswer(sanitizedAnswer);
      setSources(response.data.sources);
      setLastQuery(query);
    } catch (error) {
      console.error('Error fetching answer:', error);
      setAnswer([{
        type: 'text',
        content: '<p>I apologize, but I encountered an error while processing your request. Please try again.</p>'
      }]);
    } finally {
      setIsLoading(false);
      if (reference && inputRef.current) {
        inputRef.current.innerHTML = '';

        // Create a span for the reference with highlight
        const mentionSpan = document.createElement('span');
        mentionSpan.textContent = `@${reference.name}`;
        mentionSpan.classList.add('mention');
        mentionSpan.setAttribute('contenteditable', 'false');

        // Add the highlighted reference
        inputRef.current.appendChild(mentionSpan);

        // Add a space after the reference
        inputRef.current.appendChild(document.createTextNode(' '));

        // Set the cursor after the space
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStartAfter(inputRef.current.lastChild);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);

        // Update the query state
        setQuery(`@${reference.name} `);
      } else {
        setQuery('');
        inputRef.current.innerHTML = '';
      }
    }
  };

  const scrollToSource = useCallback((index) => {
    const sourceElement = document.getElementById(`source-${index}`);
    if (sourceElement) {
      sourceElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const renderAnswer = useCallback((htmlContent) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHtml;

    const replaceSupElements = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'sup' && node.className === 'source') {
        const number = node.textContent.replace(/[\[\]]/g, '');
        const index = parseInt(number, 10) - 1;
        const replacementNode = document.createElement('span');
        replacementNode.innerHTML = `<span class="custom-superscript" data-index="${index}">${number}</span>`;
        node.parentNode.replaceChild(replacementNode.firstChild, node);
      } else if (node.childNodes) {
        node.childNodes.forEach(replaceSupElements);
      }
    };

    replaceSupElements(tempDiv);

    return (
      <StyledAnswerContainer
        dangerouslySetInnerHTML={{ __html: tempDiv.innerHTML }}
        onClick={(e) => {
          const target = e.target;
          if (target.className === 'custom-superscript') {
            const index = parseInt(target.getAttribute('data-index'), 10);
            scrollToSource(index);
          }
        }}
      />
    );
  }, [scrollToSource]);

  const textAreaRef = useRef(null);

  const adjustTextAreaHeight = () => {
    const element = textAreaRef.current;
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${Math.min(element.scrollHeight, 150)}px`; // Limit to 150px
    }
  };

  useEffect(() => {
    adjustTextAreaHeight();
  }, [query]);

  const getFileNameFromPath = (path) => {
    if (path !== undefined) {
      const fileId = String(path).split("/").pop();
      let foundItem;
      lastReferenceType === "archive" ? foundItem = archives.find((item) => item.id === fileId) : foundItem = projectFiles.find((item) => item.id === fileId);
      return foundItem ? foundItem.name : fileId;
    } else return path;
  };

  const renderIconByType = () => {
    return <InsertDriveFileOutlinedIcon sx={{ marginRight: 1, fontSize: '1rem' }} />;
  };

  const handleLaunch = async (path) => {
    try {
      const fileId = String(path).split("/").pop();
      let foundItem;
      let ownerId = lastReferenceType === "archive" ? userArchiveIDs : projectId;
      lastReferenceType === "archive" ? foundItem = archives.find((item) => item.id === fileId) : foundItem = projectFiles.find((item) => item.id === fileId);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: ownerId,
        },
      });
      const contentType = response.data.fileType;

      if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionSelect = (option) => {
    if (option.type === 'file') {
      setReference({ type: 'file', ref: option.id, name: option.name });
    } else if (option.name === 'Library') {
      setReference({ type: 'archive', ref: userArchiveIDs, name: 'Library' });
    } else if (option.name === 'Project') {
      setReference({ type: 'project', ref: projectId, name: 'Project' });
    } else {
      // This is the 'File' option, so we should show the file list
      const newFilteredOptions = projectFiles.map((file) => ({
        ...file,
        type: 'file',
      }));
      setFilteredOptions(newFilteredOptions);
      setShowOptions(newFilteredOptions.length > 0);
      return;
    }

    const lastAtIndex = query.lastIndexOf('@');
    const beforeAt = query.slice(0, lastAtIndex);

    setQuery(beforeAt + '@' + option.name + ' ');

    // **Insert the highlighted mention into the contenteditable div**
    if (inputRef.current) {
      inputRef.current.innerHTML = '';

      // Append text before '@'
      if (beforeAt) {
        inputRef.current.appendChild(document.createTextNode(beforeAt));
      }

      // Create a span for the '@Reference' with highlight
      const mentionSpan = document.createElement('span');
      mentionSpan.textContent = '@' + option.name;
      mentionSpan.classList.add('mention');
      mentionSpan.setAttribute('contenteditable', 'false'); // Make the mention non-editable
      inputRef.current.appendChild(mentionSpan);

      // Append a space after the mention
      inputRef.current.appendChild(document.createTextNode(' '));

      // Place the cursor at the end
      const selection = window.getSelection();
      const range = document.createRange();

      // Create an empty text node and append it
      const emptyTextNode = document.createTextNode('');
      inputRef.current.appendChild(emptyTextNode);

      // Set the cursor position to the empty text node
      range.setStart(emptyTextNode, 0);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    setShowOptions(false);
    setFilteredOptions([]);
    inputRef.current.focus();
  };

  const handleKeyDown = (event) => {
    if (showOptions && filteredOptions.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          setHighlightedIndex((prevIndex) =>
            (prevIndex + 1) % filteredOptions.length
          );
          break;
        case 'ArrowUp':
          event.preventDefault();
          setHighlightedIndex((prevIndex) =>
            (prevIndex - 1 + filteredOptions.length) % filteredOptions.length
          );
          break;
        case 'Enter':
          event.preventDefault();
          handleOptionSelect(filteredOptions[highlightedIndex]);
          break;
        case 'Escape':
          setShowOptions(false);
          setFilteredOptions([]);
          break;
      }
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    } else if ((event.key === 'Backspace' || event.key === 'Delete') && reference) {
      const selection = window.getSelection();
      const cursorPosition = selection.anchorOffset;
      const textContent = event.target.textContent;

      // Find all instances of @ReferenceName in the text
      const referencePattern = new RegExp(`@${reference.name}\\s`, 'g');
      const matches = [...textContent.matchAll(referencePattern)];

      // Check if any of these instances is being deleted
      for (const match of matches) {
        const start = match.index;
        const end = start + match[0].length;

        // If cursor is at or right before the reference
        if (cursorPosition >= start && cursorPosition <= end) {
          setReference(null);
          setQuery(textContent.slice(0, start) + textContent.slice(end));
          event.preventDefault();

          // Update the contentEditable div
          if (inputRef.current) {
            inputRef.current.textContent = textContent.slice(0, start) + textContent.slice(end);

            // Set cursor position
            const range = document.createRange();
            const textNode = inputRef.current.firstChild || inputRef.current;
            range.setStart(textNode, start);
            range.collapse(true);

            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }
          break;
        }
      } if (matches.length === 0) {
        setReference(null);
        event.preventDefault();
      }
    }
  };

  const handleQueryChange = (event) => {
    // Handle paste event
    let value = event.target.innerText;
    if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'insertFromPaste') {
      // Get the pasted text content only
      const pastedText = event.target.innerText;
      // Set the input's content to just the text
      const refPattern = refItems
        .filter(item => !item.disabled)
        .map(item => `@${item.name}`)
        .join('|');
      const pattern = new RegExp(`(${refPattern})`, 'gi');
      event.target.innerHTML = pastedText.replace(pattern, '<span class="mention">$1</span>');

      // Check for reference in pasted text
      const atIndex = pastedText.indexOf('@');
      if (atIndex !== -1) {
        const afterAt = pastedText.slice(atIndex + 1);
        const matchingRef = refItems.find(item =>
          !item.disabled && afterAt.toLowerCase().startsWith(item.name.toLowerCase())
        );

        if (matchingRef) {
          // Set reference and highlight it
          setReference({
            name: matchingRef.name,
            icon: matchingRef.icon,
            type: matchingRef.type,
            ref: matchingRef.name === 'Library' ? userArchiveIDs : projectId
          });
        }
      }

      setQuery(pastedText);

      // Move cursor to end of pasted text
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(event.target);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else {
      value = event.target.innerText;
      setQuery(value);
    }
    adjustTextAreaHeight();

    // Get cursor position
    const selection = window.getSelection();
    const cursorPosition = selection.anchorOffset;
    const cursorNode = selection.anchorNode;

    // Find all @ positions in the text
    const atPositions = [];
    let pos = -1;
    let currentPos = 0;

    // Traverse through all text nodes to find @ positions
    const getAllTextNodes = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let text = node.textContent;
        while ((pos = text.indexOf('@', pos + 1)) !== -1) {
          atPositions.push({
            absolutePosition: currentPos + pos,
            node: node,
            localPosition: pos
          });
        }
        currentPos += text.length;
      } else {
        let children = Array.from(node.childNodes);
        children.forEach(child => getAllTextNodes(child));
      }
    };

    getAllTextNodes(event.target);

    // Find the @ symbol closest to cursor
    let relevantAt = null;
    let textAfterAt = '';

    if (cursorNode && cursorNode.nodeType === Node.TEXT_NODE) {
      const textUpToCursor = cursorNode.textContent.substring(0, cursorPosition);
      const lastAtBeforeCursor = textUpToCursor.lastIndexOf('@');

      if (lastAtBeforeCursor !== -1) {
        relevantAt = {
          node: cursorNode,
          localPosition: lastAtBeforeCursor
        };
        textAfterAt = cursorNode.textContent.substring(lastAtBeforeCursor + 1);
      }
    }

    // If no @ found, hide menu
    if (!relevantAt) {
      setShowOptions(false);
      setFilteredOptions([]);
      return;
    }

    // If reference exists, don't show menu even if user types @ again
    if (reference) {
      setShowOptions(false);
      setFilteredOptions([]);
      return;
    }

    // Case 1: Cursor is right after @
    if (relevantAt.localPosition === cursorPosition - 1) {
      let newFilteredOptions = refItems.filter(item => !item.disabled);
      setFilteredOptions(newFilteredOptions);
      setShowOptions(true);
      setPopperAnchorEl(inputRef.current);
      setHighlightedIndex(0);
      return;
    }

    // Case 2: There's content after @ - check for space
    const textAfterAtToCursor = textAfterAt.substring(0, cursorPosition - relevantAt.localPosition - 1);
    if (textAfterAtToCursor.startsWith(' ')) {
      setShowOptions(false);
      setFilteredOptions([]);
      return;
    }

    // Case 3: Filter based on text between @ and cursor
    const searchTerm = textAfterAtToCursor.toLowerCase().trim();
    let newFilteredOptions = refItems.filter((item) =>
      !item.disabled && item.name.toLowerCase().includes(searchTerm)
    );

    setFilteredOptions(newFilteredOptions);
    setShowOptions(newFilteredOptions.length > 0);
    setPopperAnchorEl(inputRef.current);
    setHighlightedIndex(0);
  };

  const renderLastQuery = () => {
    if (!lastQueryCopy) return null;

    const refString = lastReferenceCopy && `@${lastReferenceCopy.name}`;
    const parts = lastQueryCopy.split(refString);

    return (
      <Paper sx={{ mb: 2, padding: '12px', borderRadius: '8px', mr: 1.5, mt: 0.5 }}>
        <Typography variant="primaryText">
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <span style={{ backgroundColor: '#e0f7fa', padding: '2px 4px', borderRadius: '4px', wordBreak: 'break-word' }}>
                  {refString}
                </span>
              )}
              {part}
            </React.Fragment>
          ))}
        </Typography>
      </Paper>
    );
  };

  const handleMouseEnter = (index) => {
    setHighlightedIndex(index);
  };


  return (
    <StyledChatWidget>
      <Box sx={{ display: 'flex', mb: 2, alignItems: "flex-end" }}>

        <Box sx={{ display: 'flex', gap: "0.5rem", alignItems: "flex-end", width: "100%", justifyContent: "left" }}>
          <Typography sx={{ color: "#55828b", fontSize: "1.6rem", lineHeight: 1, fontWeight: 600 }}>Ask</Typography>
          <img
            src={smallLogo}
            alt="Ask icon"
            className="w-7 h-8"
            style={{
              marginBottom: '+3px',  // Adjust this value as needed to fine-tune alignment
              filter: 'brightness(0) saturate(100%) invert(42%) sepia(15%) saturate(1014%) hue-rotate(143deg) brightness(92%) contrast(87%)',
            }}
          />
        </Box>

        <IconButton onClick={() => dispatch(setChatWidgetOpen(false))} sx={{ p: 0 }}>
          <CloseIcon sx={{ fontSize: "1.25rem" }} />
        </IconButton>
      </Box>
      <ScrollableContainer>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Loader type="ball-grid-pulse" />
          </Box>
        ) : answer ? (

          <>
            {renderLastQuery()}
            {answer.map((item, index) => (
              <Paper elevation={1} key={index} sx={{ mb: 2, padding: '12px', borderRadius: '8px', mr: 1.5 }}>
                <Typography variant="primaryText" sx={{ wordBreak: 'break-word' }}>
                  {renderAnswer(item.content)}
                </Typography>
              </Paper>
            ))}
            {sources.length > 0 && (<Box><Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 1.5 }}>
              <IconButton
                onClick={copyAnswer}
                title="Copy answer"
              >
                {isCopied ? (
                  <DoneRoundedIcon sx={{ fontSize: "0.875rem" }} />
                ) : (
                  <ContentPasteRoundedIcon sx={{ fontSize: "0.875rem" }} />
                )}

              </IconButton>
            </Box>
              <Divider sx={{ my: 2 }} /><Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1, color: '#555' }}>Sources:</Typography>
              <Box sx={{ mr: 1.5 }}>
                {[...sources]
                  .sort((a, b) => (a.uuid > b.uuid ? 1 : -1))
                  .map((source, index) => (
                    <Accordion key={index} id={`source-${index}`} disableGutters>
                      <AccordionSummary sx={{ gap: '2rem' }} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="primaryText" sx={{ wordBreak: 'break-word' }}> [{source.uuid}] {getFileNameFromPath(source.source_path)}</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <ReadOnlyTipTapEditor content={source.extract} />
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '2%',
                          marginBottom: '1.5%'
                        }}>
                          {renderIconByType()}
                          <Typography
                            variant="caption"
                            sx={{
                              marginRight: 'auto',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: '70%',
                            }}
                            title={getFileNameFromPath(source.source_path)}
                          >
                            {getFileNameFromPath(source.source_path)}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => handleLaunch(source.source_path)}
                          >
                            <LaunchRoundedIcon sx={{ fontSize: '1rem' }} />
                          </IconButton>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}</Box>
            </Box>
            )}
          </>
        ) : (
          <StyledPaper>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500, color: 'text.primary' }}>
              How can X help you today?
            </Typography>

            <StepTypography>
              Remember to use @ if you want X to consider a specific file or your library.
            </StepTypography>
          </StyledPaper>
        )}
      </ScrollableContainer>
      <StyledInputBox>
        <StyledInput>
          <StyledContentEditable
            ref={(el) => {
              inputRef.current = el;
              textAreaRef.current = el;
            }}
            contentEditable
            onInput={handleQueryChange}
            onKeyDown={handleKeyDown}
            data-placeholder="@Library give me information on XYZ client"
            suppressContentEditableWarning={true}
          />
        </StyledInput>
        <IconButton
          onClick={handleSubmit}
          disabled={!stripReference(query).trim()}
          sx={{
            color: !stripReference(query).trim() ? 'inherit' : '#55828b',
          }}
        >
          <SendRoundedIcon sx={{ fontSize: "1.25rem" }} />
        </IconButton>
      </StyledInputBox>
      <Popper
        open={showOptions && filteredOptions.length > 0}
        anchorEl={popperAnchorEl}
        placement="top-start"
        style={{
          zIndex: 1301,
          width: '25%',
        }}
      >
        <ClickAwayListener onClickAway={() => { setShowOptions(false); setFilteredOptions([]); }}>
          <Paper elevation={3} sx={{ bgcolor: 'white', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', maxHeight: '200px', overflow: 'auto' }}>
            <List sx={{ padding: 0 }}>
              {filteredOptions.map((item, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleOptionSelect(item)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  selected={index === highlightedIndex}
                  sx={{
                    backgroundColor: index === highlightedIndex ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '30px' }}>
                    {item.type === 'file' ? <InsertDriveFileIcon sx={{ fontSize: '1.2rem' }} /> : item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "0.875rem",
                        lineHeight: 1.2,
                        color: "#757575",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      },
                    }}
                    primary={item.name}
                  />


                  <ListItemSecondaryAction>
                    {item.name === 'Project File' && (
                      <ArrowForwardRoundedIcon sx={{ fontSize: '1rem', color: '#757575' }} />
                    )}
                  </ListItemSecondaryAction>

                </ListItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </StyledChatWidget >
  );
};

export default ChatWidget;