import { createSlice } from '@reduxjs/toolkit';

const chatWidgetSlice = createSlice({
  name: 'chatWidget',
  initialState: {
    isOpen: false
  },
  reducers: {
    setChatWidgetOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  }
});

export const { setChatWidgetOpen } = chatWidgetSlice.actions;
export const getChatWidgetStatus = (state) => state.chatWidget.isOpen;
export default chatWidgetSlice.reducer;
