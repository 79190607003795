import React from 'react';
import { Box } from "@mui/material";
import TipTapEditor from "../TipTapEditor";

const RequirementsView = ({
  reqsRef,
  projectId,
  userArchiveIDs,
  fetchProjectData,
  itemStatus
}) => (
  <Box sx={{ height: "100%", overflowY: "auto" }}>
    <TipTapEditor
      rteRef={reqsRef}
      projectId={projectId}
      archiveIds={userArchiveIDs.map((item) => item.id)}
      refreshItems={fetchProjectData}
      showToolbar={false}
      displayItem="requirements"
      readOnly={itemStatus?.isGenerating}
    />
  </Box>
);

export default RequirementsView;