import React, { useEffect, useRef, useState } from "react";
import { Paper, Box, CircularProgress, Typography, IconButton } from "@mui/material";
import TipTapEditor from "../TipTapEditor";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import DragDropFileUploader from "../DragnDropFileUploader";
import { updateItem } from "../../reduxstore/itemsSlice";
import PDFViewer from "./PDFViewer";
import { api } from "../../store";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

const DraftAnswerPanel = styled(Paper)(({ theme }) => ({
  width: "calc(50% - 16px)",
  minWidth: "calc(50% - 16px)",
  maxWidth: "calc(50% - 16px)",
  border: "1px solid #ddd",
  height: "100%",
  ...theme.typography.body2,
  overflow: "hidden",
}));

const DraftEditorPanel = ({ projectId, userArchiveIDs, refreshItems, processProjectFile }) => {
  const selectedItem = useSelector((state) => state.selectedItem);
  const rteRef = useRef(null);
  const dispatch = useDispatch();
  const pdfViewerRef = useRef(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [doc, setDoc] = useState(null);
  const projectFiles = useSelector((state) => state.projectFiles.files);
  const items = useSelector((state) => state.items);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const updateFileId = (key, value) => {
    dispatch(
      updateItem({
        uuid: selectedItem.uuid,
        key: key,
        value: value,
        projectId,
      })
    );
  };

  useEffect(() => {
    const getSignedURL = async () => {
      if (selectedItem.type === "Form" && selectedItem.file_id) {
        try {
          setIsFileLoading(true);
          const metadata = await api.post("/file/getMetadataForFile", { fileId: selectedItem.file_id });
          const contentType = metadata.data.metadata.contentType;
          const response = await api.get(`file/getSignedURL/${selectedItem.file_id}`);
          const url = response.data.url;
          setDoc({ type: contentType, url });

        } catch (error) {
          console.error("Error fetching signed URL:", error);
        } finally {
          setIsFileLoading(false);
        }
      } else {
        setIsFileLoading(false);
        setDoc(null);
      }
    };

    getSignedURL();
    setIsUploadDialogOpen("");
  }, [selectedItem.file_id, selectedItem.type]);

  const refreshUrl = async () => {
    const response = await api.get(`file/getSignedURL/${selectedItem.file_id}`);
    const url = response.data.url;
    setDoc({ type: doc.type, url });
  };

  const isFileIdUsed = (fileId) => {
    return items.some(item => {
      if (item.file_id === fileId) {
        return true;
      }
      if (item.type === "free text" && item.sections) {
        return item.sections.some(section => section.file_id === fileId);
      }
      return false;
    });
  };

  const handleDeleteFile = () => {
    dispatch(updateItem({ uuid: selectedItem.uuid, key: "file_id", value: null, projectId }));
  };

  const onDownload = () => {
    window.open(doc.url, "_blank");
  };

  const renderContent = () => {
    if (selectedItem.type === "Form") {
      if (!selectedItem.file_id || isUploadDialogOpen === selectedItem.uuid) {
        return (
          <DragDropFileUploader
            projectId={projectId}
            processProjectFile={(filePath) => processProjectFile(filePath)}
            updateItem={(key, value) => {
              updateFileId(key, value);
              setIsUploadDialogOpen(false);
            }}
            formFiles={projectFiles.filter((file) =>
              Array.isArray(file.tag) &&
              file.tag.length === 1 &&
              file.tag[0] === "forms" &&
              !isFileIdUsed(file.id)
            )}
            setIsFileLoading={setIsFileLoading}
          />
        );
      } else if (isFileLoading) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        );
      } else if (doc) {
        if (doc.type === "application/pdf") {
          return (
            <PDFViewer
              ref={pdfViewerRef}
              fileUrl={doc.url}
              initialPage={1}
              defaultScale={1}
              fileName={projectFiles.find((file) => file.id === selectedItem.file_id)?.name || "Document"}
              showToolbar={false}
              onDownload={() => {
                window.open(doc, "_blank");
              }}
              onDelete={handleDeleteFile}
              refreshUrl={refreshUrl}
            />
          )
        } else {
          const fileName = projectFiles.find((file) => file.id === selectedItem.file_id)?.name || "Document";
          return (
            <Box sx={{ height: "100%" }}>
              <Box sx={{ bgcolor: "background.paper", display: "flex", justifyContent: "space-between", px: 2 }}>
                <Box sx={{ width: "60%", display: "flex", alignItems: "center", cursor: "default" }} title={fileName}>
                  <Typography variant="primaryText" noWrap sx={{ textOverflow: "ellipsis" }}>
                    {fileName}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "start" }}>
                  <IconButton title="Download" onClick={onDownload}><DownloadIcon sx={{ fontSize: "1rem" }} /></IconButton>
                  <IconButton title="Reupload file" onClick={handleUpload}><FileUploadRoundedIcon sx={{ fontSize: "1rem" }} /></IconButton>
                </Box>
              </Box>
              <DocViewer
                documents={[{ uri: doc.url }]}
                pluginRenderers={DocViewerRenderers}
                prefetchMethod="GET"
                config={{
                  header: {
                    disableHeader: true,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
                style={{
                  height: "calc(100% - 40px)",
                  width: "100%",
                  maxHeight: "calc(100% - 40px)",
                  overflow: "auto",
                }}
              />
            </Box>

          );
        }
      } else {
        return <Typography>No content available</Typography>;
      }
    } else {
      if (selectedItem.type === "Free Text" && selectedItem.answer) {
        return (
          <TipTapEditor
            rteRef={rteRef}
            projectId={projectId}
            archiveIds={userArchiveIDs}
            refreshItems={(uuid, isNew) => refreshItems(uuid, isNew)}
            showToolbar={true}
            displayItem="answer"
          />
        );
      } else if (selectedItem.type === "Free Text" && !selectedItem.answer) {
        return (
          <Box sx={{ height: "100%" }}>
            <Paper
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: "inherit",
                justifyContent: 'center',
                alignItems: 'center',
                padding: 3,
                transition: 'all 0.3s ease',
              }}
            >
              <Typography sx={{
                mb: 2, color: "black", width: '100%',
                fontSize: "0.875rem", fontWeight: "500", justifyContent: "center",
                textAlign: "center"
              }}>
                Click the sections under this item - <span style={{ fontStyle: 'italic', fontWeight: '600' }}>{selectedItem.title}</span> - to view responses
              </Typography>
            </Paper>
          </Box>
        );
      }
    }
  };

  return <DraftAnswerPanel>{renderContent()}</DraftAnswerPanel>;
};

export default DraftEditorPanel;