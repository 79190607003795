import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserStatus } from '../reduxstore/UserStatusSlice';
import supabase from '../supabaseclient';
import { api, api_external, loginWithDataTokenAndProfile } from '../store';
import { AuthContext } from '../contexts/AuthContext';

const setApiDefaults = (token, profile) => {
  if (token && profile) {
    api.defaults.headers.common["x-access-token"] = token;
    api_external.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    api_external.defaults.headers.common["user_id"] = profile._id;
  }
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const getUserAuthorization = async (email) => {
    try {
      const res = await api.post("auth/signin", {
        email: email.trim(),
      });
      return res;
    } catch (err) {
      console.error("Authorization error:", err);
      throw err;
    }
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          const res = await getUserAuthorization(session.user.email);
          if (res?.data?.profile) {
            setLoginStatus(true);
            await loginWithDataTokenAndProfile(session.access_token, res.data.profile);
            setApiDefaults(session.access_token, res.data.profile);
            dispatch(setUserStatus(res.data.profile));
          }
        }
      } catch (error) {
        console.error('Session check error:', error);
      } finally {
        setLoading(false);
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN') {
        try {
          const res = await getUserAuthorization(session.user.email);
          if (res?.data?.profile) {
            setLoginStatus(true);
            await loginWithDataTokenAndProfile(session.access_token, res.data.profile);
            setApiDefaults(session.access_token, res.data.profile);
            dispatch(setUserStatus(res.data.profile));
          }
        } catch (error) {
          console.error('Auth state change error:', error);
        }
      } else if (event === 'SIGNED_OUT') {
        setLoginStatus(false);
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        dispatch(setUserStatus(null));
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [dispatch]);

  const onLogin = () => {
    setLoginStatus(true);
  };

  const onLogout = async () => {
    try {
      await supabase.auth.signOut();
      setLoginStatus(false);
      localStorage.removeItem('token');
      localStorage.removeItem('profile');
      dispatch(setUserStatus(null));
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, onLogin, onLogout }}>
      {children}
    </AuthContext.Provider>
  );
};