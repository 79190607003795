import React from 'react';
import {
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { styled } from '@mui/system';

const Input = styled('input')({
  display: 'none',
});

const FileUploader = (props) => {
  const handleFileUpload = (event) => {
    const fileList = event.target.files;

    const newFiles = Array.from(fileList);
    if (props.filesAndStatus.length + newFiles.length <= props.maxFiles) {
      const newFilesWithStatus = newFiles.map((file) => ({
        file,
        status: 'edit',
        tag: null,
      }));

      const updatedFilesAndStatus = [
        ...props.filesAndStatus,
        ...newFilesWithStatus,
      ];

      props.updateFileUploaderStatus(updatedFilesAndStatus);
    } else {
      props.onFileNumberExceeded();
    }
  };

  const handleFileRemove = (removedFile) => {
    props.updateFileUploaderStatus(
      props.filesAndStatus.filter((fs) => fs.file !== removedFile)
    );
  };

  const handleTagChange = (index, selectedTag) => {
    const updatedFilesAndStatus = [...props.filesAndStatus];
    updatedFilesAndStatus[index] = {
      ...updatedFilesAndStatus[index],
      tag: selectedTag,
    };
    props.updateFileUploaderStatus(updatedFilesAndStatus);
  };

  const isFileError = (fileName) => {
    return props.uploadErrorMessages.some(msg => msg.includes(fileName));
  };

  return (
    <Box>
      {props.uploadError && (
        <Typography color="error" variant="primaryText" gutterBottom>
          Oops something went wrong during upload, please check the
          requirements. If the issue persists contact: support@xpub.ai
        </Typography>
      )}
      {props.fileNumberExceeded && (
        <Typography color="error" variant="primaryText" gutterBottom>
          Limit exceeded: upload fewer than {props.maxFiles} files at once.
        </Typography>
      )}
      {props.uploadErrorMessages.length > 0 && (
        <div>
          {props.uploadErrorMessages.map((errorMessage, index) => (
            <Typography key={index} color="error" variant="primaryText" gutterBottom>
              {errorMessage}
            </Typography>
          ))}
        </div>
      )}
      <label htmlFor="contained-button-file">
        <Input
          accept={[
            ...props.acceptTypes,
            ...(props.allowAudioFiles ? ['.mp3', '.mp4', '.mpeg'] : []),
          ]}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleFileUpload}
        />
        <Button variant="contained" component="span" startIcon={<FileUploadRoundedIcon />}>
          Add Files
        </Button>
      </label>

      <List sx={{ width: '100%' }}>
        {props.filesAndStatus.map((fileStatus, index) => {
          const hasError = isFileError(fileStatus.file.name);
          const isTagMissing = !fileStatus.tag && props.showTagErrors;
          return (
            <ListItem
              key={index}
              sx={{
                border: hasError ? '1px solid red' : '1px solid #ccc',
                borderRadius: 1,
                my: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
                alignItems: 'center',
                padding: 2,
                '& .MuiSelect-select': {
                  minHeight: '20px',
                  lineHeight: '20px',
                  fontSize: '0.875rem',
                },
                backgroundColor: 'inherit',
              }}
            >
              <ListItemText
                primary={fileStatus.file.name}
                primaryTypographyProps={{
                  sx: {
                    fontSize: "0.875rem",
                    lineHeight: 1.2,
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                  },
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {props.displayTags && props.tagOptions && (
                  <Box>
                    <FormControl sx={{ minWidth: 150, mr: 2 }} size="small" error={isTagMissing}>
                      <InputLabel id={`tag-select-label-${index}`}>File Tag *</InputLabel>
                      <Select
                        labelId={`tag-select-label-${index}`}
                        id={`tag-select-${index}`}
                        value={fileStatus.tag || ''}
                        label="File Tag *"
                        onChange={(event) => handleTagChange(index, event.target.value)}
                        disabled={fileStatus.status === 'uploading'}
                        required
                      >
                        {props.tagOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            disabled={!option.enabled && fileStatus.tag !== option.id}
                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                          >
                            {option.label}
                            {!fileStatus.tag && (
                              <Box sx={{ marginLeft: 'auto' }}>
                                <Tooltip
                                  title={
                                    option.id === 'RFX' ?
                                      "Primary file containing key project details and requirements" :
                                      option.id === 'forms' ?
                                        "Supplementary forms that need to be completed with the proposal" :
                                        "Other supplmentary files like addendums, appendices, etc."
                                  }
                                  placement="right"
                                >
                                  <InfoIcon sx={{ fontSize: 16, color: 'action.active' }} />
                                </Tooltip>
                              </Box>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                      {isTagMissing && <FormHelperText>Tag is required</FormHelperText>}
                    </FormControl>
                  </Box>
                )}
                {fileStatus.status === 'uploading' ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleFileRemove(fileStatus.file)}
                    size="small"
                  >
                    <ClearRoundedIcon fontSize="0.875rem" />
                  </IconButton>
                )}
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default FileUploader;