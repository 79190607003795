import React from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BlockIcon from '@mui/icons-material/Block';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

const StatusCell = ({ params, onStatusChange, variant = 'table', userId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (newStatus) => {
    handleClose();
    onStatusChange(params.row.rfx_id, newStatus);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    handleStatusChange(null);
  };

  const getStatusIcon = (status) => {
    const iconSize = variant === 'detail' ? '1.2rem' : '1.1rem';

    switch (status) {
      case 'favorite':
        return <FavoriteIcon sx={{ color: 'error.main', fontSize: iconSize }} />;
      case 'maybe':
        return <HelpOutlineIcon sx={{ color: 'warning.main', fontSize: iconSize }} />;
      case 'no':
        return <BlockIcon sx={{ color: 'text.disabled', fontSize: iconSize }} />;
      default:
        return <LabelOutlinedIcon sx={{ color: 'text.disabled', fontSize: iconSize }} />;
    }
  };

  const currentStatus = (() => {
    try {
      return params.row?.status ? params.row.status.find(s => s.userId === userId)?.status || null : null;
    } catch (error) {
      console.error('Error getting status:', error);
      return null;
    }
  })();

  const menuItems = [
    { status: 'favorite', label: 'Shortlisted' },
    { status: 'maybe', label: 'Maybe' },
    { status: 'no', label: 'Not Interested' },
  ];

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      ...(variant === 'detail' && {
        marginLeft: 2,
        '& .MuiIconButton-root': {
          padding: '8px'
        }
      })
    }}>

      <IconButton
        title="Set RFx Status"
        size={variant === 'detail' ? 'medium' : 'small'}
        onClick={handleClick}
        sx={{
          padding: variant === 'detail' ? '8px' : '4px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        {getStatusIcon(currentStatus)}
        {/* <MoreVertIcon sx={{ fontSize: variant === 'detail' ? '1.2rem' : '1rem' }} /> */}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 150,
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.status}
            onClick={() => handleStatusChange(item.status)}
            selected={currentStatus === item.status}
            sx={{ py: 1 }}
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              {getStatusIcon(item.status)}
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                sx: { fontSize: '0.875rem' }
              }}
            />
          </MenuItem>
        ))}
        {currentStatus && (
          <MenuItem onClick={handleClear} sx={{ py: 1 }}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <LabelOutlinedIcon sx={{ color: 'text.disabled', fontSize: '1.1rem' }} />
            </ListItemIcon>
            <ListItemText
              primary="None"
              primaryTypographyProps={{
                sx: { fontSize: '0.875rem' }
              }}
            />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default StatusCell;