import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItems } from "../reduxstore/itemsSlice";
import { setSelectedItem } from "../reduxstore/selectedItemSlice";
import {
  initializeItemsStatus,
  updateItemStatus,
} from "../reduxstore/itemsStatusSlice";
import { setProjectFiles, setCurrentFile, setError } from "../reduxstore/projectFilesSlice";
import { useLocation } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Stack,
  Button,
  TextField,
  SnackbarContent,
} from "@mui/material";
import { api, api_external } from "../store";
import "react-quill/dist/quill.snow.css";
import "./Editor.scss";
import "./RFPEditor.scss";
import env from "../config";
import { styled } from "@mui/material/styles";
import RFPChecklist from "../Components/rfp/RFPChecklist";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import "@cyntler/react-doc-viewer/dist/index.css";
import RFPChecklistSkeleton from "../Components/rfp/RFPCheckListSkeleton";
import RFPStructure from "../Components/rfp/RFPStructure";
import DraftRightPanel from "../Components/rfp/DraftRightPanel";
import Snackbar from "@mui/material/Snackbar";
import ErrorIcon from "@mui/icons-material/Error";
import ExportModal from "../Components/rfp/ExportModal";
import { saveAs } from "file-saver";
import DraftEditorPanel from "../Components/rfp/DraftEditorPanel";
import ChatWidget from "../Components/ChatWidget";
import DocumentLeftPanelComponent from "../Components/rfp/DocumentLeftPanelComponent";
import DocumentViewerComponent from "../Components/rfp/DocumentViewerComponent";
import AnimatedChatFab from "../Components/AnimatedFab";
import { REGEX_TODO } from "../Components/TipTapEditor";
import { addUpdateConfig } from "../reduxstore/itemsSlice";
import { refreshSelectedItem } from "../reduxstore/selectedItemSlice";

function RFPEditor() {
  const { state, search } = useLocation();
  const [tab, setTab] = React.useState("overview");
  const [rfpCheckListData, setRFPCheckListData] = useState(null);
  const archives = useSelector(state => state.userStatus.archives);
  const archiveIds = useSelector(state => state.userStatus.userArchiveIDs);
  const [isCheckListLoading, setIsChecklistLoading] = useState(true);
  const dispatch = useDispatch();
  const projectFiles = useSelector((state) => state.projectFiles);
  const user = useSelector((state) => state.userStatus.user);
  const isChatOpen = useSelector(state => state.chatWidget.isOpen);


  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(search);
  const projectId = queryParams.get("projectId") || state?.projectId;
  // const projectName = queryParams.get("projectName") || state?.projectName;
  const [projectName, setProjectName] = useState(queryParams.get("projectName") || state?.projectName);
  const [lastPages, setLastPages] = useState({});
  const pdfViewerRef = useRef(null);
  const docxViewerRef = useRef(null);

  const [projectNameEditing, setProjectNameEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(projectName);
  const [projectExporting, setProjectExporting] = useState(false);
  const [exportErr, setExportError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [draftError, setDraftError] = useState(false);

  const availableTagOptions = useMemo(() => {
    const options = [];
    options.push({ id: "RFX", label: "RFX File", enabled: false });
    options.push({ id: "forms", label: "Form", enabled: true });
    options.push({ id: "other", label: "Other", enabled: true });
    return options;
  }, []);

  const countTodos = (text) => {
    const regex = REGEX_TODO;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  };

  const handleProjectNameChange = (event) => {
    setEditedProjectName(event.target.value);
  };

  const handleProjectNameSubmit = async () => {
    if (editedProjectName.trim() !== projectName) {
      try {
        await api.put("/project/updateProjectName", {
          projectId,
          projectName: editedProjectName.trim(),
        });
        // Update the URL with the new project name
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set("projectName", editedProjectName.trim());
        window.history.replaceState({}, "", newUrl.toString());

        setProjectName(editedProjectName.trim());
      } catch (error) {
        console.error("Error updating project name:", error);
        // Revert to the original name if there's an error
        setEditedProjectName(projectName);
      }
    }
    setProjectNameEditing(false);
  };

  const refreshProjectData = async (sectionUUID, isNew = false) => {
    try {
      const response = await api.get("/project/getProjectById", {
        params: { projectId },
      });
      let filteredItems = [];
      if (response.data?.project.rfx_response) {
        filteredItems =
          response.data.project.rfx_response.documents;
        await dispatch(setItems(filteredItems));
        const refreshedItem = filteredItems.reduce((found, item) => {
          // If already found, return it
          if (found) return found;

          // Look through sections
          if (item.sections) {
            const matchingSection = item.sections.find(section =>
              section.uuid === sectionUUID
            );
            if (matchingSection) return matchingSection;

            // Look through subsections if needed
            for (const section of item.sections) {
              const matchingSubsection = section.subsections?.find(subsection =>
                subsection.uuid === sectionUUID
              );
              if (matchingSubsection) return matchingSubsection;
            }
          }

          return found;
        }, null);
        dispatch(refreshSelectedItem({ uuid: sectionUUID, payload: refreshedItem }));

        // for the section uuid, update the todo count
        if (sectionUUID != null && isNew) {
          const item = filteredItems.find(item => {
            if (item.type === "Free Text") {
              return item.sections.some(section => section.uuid === sectionUUID);
            }
            return item.uuid === sectionUUID;
          });
          if (!item) {
            console.error("Item not found");
            return;
          }
          if (item.type === "Free Text") {
            const newTodoCount = countTodos(
              Array.isArray(item.answer)
                ? item.answer[item.answer.length - 1]
                : item.answer || ""
            );
            dispatch(
              updateItemStatus({
                uuid: sectionUUID,
                todoCount: newTodoCount,
              })
            );
          }
        }
      }
      return filteredItems;
    } catch (error) {
      console.error("Error refreshing project data:", error);
    }
  };


  const initializeProjectData = async () => {
    try {
      await dispatch(addUpdateConfig({ projectId })).unwrap();

      const response = await api.get("/project/getProjectById", {
        params: { projectId },
      });
      if (response.data?.project.rfx_overview) {
        setRFPCheckListData(response.data.project.rfx_overview);
      } else if (response.data?.project.rfx_checklist) {
        setRFPCheckListData(response.data.project.rfx_checklist);
      }
      setIsChecklistLoading(false);
      if (response.data?.project.rfx_response) {
        const filteredItems =
          response.data.project.rfx_response.documents;

        dispatch(setItems(filteredItems));
        // here for all items, section and subsections, add updated_config in the item and also in db

        // Initialize item status for Free Text items with sections
        const itemsWithSections = filteredItems.flatMap(item => {
          if (item.type === "Free Text") {
            if (item.sections && item.sections.length > 0) {
              return [
                {
                  uuid: item.uuid,
                  type: item.type,
                },
                ...item.sections.flatMap(section => {
                  const sectionStatus = {
                    uuid: section.uuid,
                    type: section.type,
                    todoCount: Array.isArray(section.answer)
                      ? (section.answer.length > 0 ? countTodos(section.answer[section.answer.length - 1]) : 0)
                      : countTodos(section.answer || ""),
                    version: Array.isArray(section.answer)
                      ? section.answer.length - 1
                      : undefined,
                  };

                  if (section.subsections && section.subsections.length > 0) {
                    const subsectionStatuses = section.subsections.map(subsection => {
                      return {
                        uuid: subsection.uuid,
                        type: subsection.type,
                        todoCount: Array.isArray(subsection.answer)
                          ? (subsection.answer.length > 0 ? countTodos(subsection.answer[subsection.answer.length - 1]) : 0)
                          : countTodos(subsection.answer || ""),
                        version: Array.isArray(subsection.answer)
                          ? subsection.answer.length - 1
                          : undefined,
                      };
                    });
                    return [sectionStatus, ...subsectionStatuses];
                  }

                  return [sectionStatus];
                })
              ];
            } else {
              return [{
                uuid: item.uuid,
                type: item.type,
                todoCount: countTodos(item.answer || ""),
                version: undefined,
              }];
            }
          } else {
            return [{
              uuid: item.uuid,
              type: item.type,
              todoCount: countTodos(item.answer || ""),
              version: undefined,
            }];
          }
        });
        dispatch(
          initializeItemsStatus(
            itemsWithSections
          )
        );

        if (filteredItems.length > 0) {
          dispatch(
            setSelectedItem({
              uuid: filteredItems[0].uuid,
              description: filteredItems[0].description,
              sections: filteredItems[0].sections ? filteredItems[0].sections : [],
              subsections: filteredItems[0].subsections ? filteredItems[0].subsections : [],
              title: filteredItems[0].title,
              type: filteredItems[0].type,
              file_id: filteredItems[0].type === "Form" ? filteredItems[0].file_id : "",
              answer: filteredItems[0].type === "Free Text" ? filteredItems[0].answer : "",
              sources: filteredItems[0].type === "Free Text" ? filteredItems[0].sources : [],
              requirements: filteredItems[0].requirements ? filteredItems[0].requirements : "",
              updated_config: filteredItems[0].updated_config ? filteredItems[0].updated_config : {
                title: filteredItems[0].title,
                type: filteredItems[0].type,
                description: filteredItems[0].description,
                requirements: filteredItems[0].requirements,
                sources: filteredItems[0].sources,
              },
            })
          );
        }
      } else {
        setDraftError(true);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const fetchProjectFiles = async (activeDocument) => {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: projectId },
        headers: { "Cache-Control": "no-cache" },
      });
      let files = fileList.data;

      // Create a map of existing files for quick lookup
      const existingFilesMap = new Map(files.map(file => [file.id, file]));

      const updatedFiles = await Promise.all(
        files.map(async (file) => {
          // Check if we already have this file's complete information
          if (file.uri && file.metadata) {
            return file;
          }

          // If we don't have the file's complete information, fetch it
          const [signedResponse, metadataResponse] = await Promise.all([
            api.get(`file/getSignedURL/${file.id}`),
            api.post("/file/getMetadataForFile", { fileId: file.id })
          ]);

          return {
            ...file,
            uri: signedResponse.data.url,
            metadata: metadataResponse.data,
          };
        })
      );

      dispatch(setProjectFiles(updatedFiles));

      if (updatedFiles.length > 0) {
        if (projectFiles.currentFile === null || projectFiles.currentFile === undefined) {
          dispatch(setCurrentFile(updatedFiles[0]));
        } else {
          // Ensure we're using the updated version of the active document
          const updatedActiveFile = updatedFiles.find(file => file.id === projectFiles.currentFile.id) || updatedFiles[0];
          dispatch(setCurrentFile(updatedActiveFile));
        }
      }
    } catch (err) {
      console.error("Error fetching project files:", err);
      console.error("Error response:", err?.response?.data?.message);
      dispatch(setError("Failed to fetch project files"));
    }
  };

  useEffect(() => {
    initializeProjectData();
    fetchProjectFiles();
  }, [projectId]);

  const handleTabChange = (event, newValue) => {
    if (
      tab === "documents" &&
      newValue !== "documents" &&
      pdfViewerRef.current !== null &&
      projectFiles.currentFile
    ) {
      // Leaving the documents tab, save the current page for the active document
      setLastPages((prevPages) => ({
        ...prevPages,
        [projectFiles.currentFile.uri]: pdfViewerRef.current.getCurrentPage(),
      }));
    } else if (
      tab === "documents" &&
      newValue !== "documents" &&
      docxViewerRef.current !== null &&
      projectFiles.currentFile
    ) {
      console.log("docxViewerRef.current", docxViewerRef.current);
      console.log("docxViewerRef.current.getCurrentPage()", docxViewerRef.current.getCurrentPage());
      setLastPages((prevPages) => ({
        ...prevPages,
        [projectFiles.currentFile.uri]: docxViewerRef.current.getCurrentPage(),
      }));
    }
    setTab(newValue);
  };

  const DocumentLeftPanel = styled(Paper)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "calc(20% - 8px)", // Subtracting half of the spacing
    minWidth: "calc(20% - 8px)",
    maxWidth: "calc(20% - 8px)",
    border: "1px solid #ddd",
    ...theme.typography.body2,
  }));

  const handleDocumentClick = async (doc) => {
    try {
      const matchingDoc = projectFiles.files.find((d) => d.uri.includes(doc.id));
      dispatch(setCurrentFile(matchingDoc));
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };

  const processProjectFile = async (filePath) => {
    try {
      const requestData = {
        project_id: projectId,
        file_id: filePath,

      };
      await api_external.post(`${env.salesPubAPI}/process-project-file`, requestData);
    } catch (error) {
      console.error("Error processing project file:", error);
      throw error;
    }
  };

  const handleItemClick = useCallback(
    (item, parentId) => {
      dispatch(
        setSelectedItem({
          uuid: item.uuid,
          description: item.description,
          subsections: item.subsections,
          parent_id: parentId ? parentId : null,
          title: item.title,
          type: item.type,
          file_id: item.type === "Form" ? item.file_id : "",
          answer: item.type === "Free Text" ? item.answer : "",
          sources: item.type === "Free Text" ? item.sources : [],
          requirements: item.requirements ? item.requirements : "",
          updated_config: item.updated_config ? item.updated_config : {
            title: item.title,
            type: item.type,
            description: item.description,
            requirements: item.requirements,
            sources: item.sources,
          },
        })
      );
    },
    [dispatch]
  );

  const handleExport = async (filename, include_todos) => {
    setExportError(false);
    setProjectExporting(true);
    if (!projectId) {
      return;
    }

    try {
      const requestData = {
        project_id: projectId,
        include_todos: include_todos,
        org_id: user.organizationId,
      };

      // Start the export process
      const response = await api_external.post(
        `${env.salesPubAPI}/export-rfp`,
        requestData,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: 'application/zip' });
      saveAs(blob, `${filename}`);

      // Notify the service worker about the successful download
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "DOWNLOAD_COMPLETE",
          payload: { filename: filename },
        });
      }
    } catch (error) {
      console.error("Error during export:", error);
      setExportError(true);

      // Notify the service worker about the failed download
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "DOWNLOAD_FAILED",
          payload: { filename, error: error.message },
        });
      }
    } finally {
      setProjectExporting(false);
    }
  };

  const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "500",
    width: "8rem",
    minHeight: "3rem",
  }));

  const handleRFPDataUpdate = async (newData) => {
    try {
      await api.put("/project/updateRFPChecklist", {
        projectId,
        rfx_overview: newData,
      });
    } catch (error) {
      console.error("Error updating RFP Checklist:", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setExportError(false);
  };

  const refreshUrl = async () => {
    const response = await api.get(`file/getSignedURL/${projectFiles.currentFile.id}`);
    const url = response.data.url;
    dispatch(setCurrentFile({ ...projectFiles.currentFile, uri: url }));
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem 1rem",
          bgcolor: "background.paper",
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", color: "#333" }}>
          <TextField
            value={editedProjectName}
            onChange={handleProjectNameChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleProjectNameSubmit();
                e.preventDefault();
                e.target.blur(); // Unfocus the input field
              }
            }}
            onBlur={handleProjectNameSubmit}
            autoFocus={projectNameEditing}
            inputProps={{ maxLength: 100 }}
            title="Rename"
            sx={{
              "& .MuiInputBase-input": {
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "1.2rem",
                letterSpacing: "0.05em",
                width: "40rem",
                border: "none",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 0,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #44686f",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #719fa8",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "0rem 0.3rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.10)",
            position: "relative",
            zIndex: 1000,
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="project tabs"
          >
            <AntTab
              value="overview"
              label="Overview"
              icon={<DashboardRoundedIcon />}
              iconPosition="start"
              sx={{ marginRight: "1rem" }}
            />
            {user?.featureAccess?.generation &&
              <AntTab
                value="draft"
                label="Draft"
                icon={<EditNoteRoundedIcon />}
                iconPosition="start"
                sx={{ marginRight: "1rem" }}
              />}

            <AntTab
              value="documents"
              label="Documents"
              icon={<FolderOpenRoundedIcon />}
              iconPosition="start"
            />
          </Tabs>
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="contained"
            startIcon={<IosShareRoundedIcon />}
            // startIcon={
            //   projectExporting ? (
            //     <CircularProgress size={20} color="inherit" />
            //   ) : (
            //     <IosShareRoundedIcon />
            //   )
            // }
            sx={{
              textTransform: "none",
              marginRight: 2,
              height: "70%",
              position: "relative",
              overflow: "hidden",
              "&::after": projectExporting ? {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent)",
                animation: "shimmer 1.5s infinite",
              } : {},
              "@keyframes shimmer": {
                "0%": { transform: "translateX(-100%)" },
                "100%": { transform: "translateX(100%)" }
              }
            }}
            disableRipple
            disabled={projectExporting}
          >
            {projectExporting ? "Exporting..." : "Export"}
          </Button>
        </Box>

        <Box sx={{ flexGrow: 1, overflow: "auto", padding: "0.5rem" }}>
          {tab === "overview" &&
            (isCheckListLoading ? (
              <RFPChecklistSkeleton />
            ) : (
              <RFPChecklist
                RFPData={rfpCheckListData}
                onRFPDataUpdate={handleRFPDataUpdate}
              />
            ))}

          {tab === "draft" &&
            <Stack
              direction="row"
              spacing={1}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                padding: "0rem 0.5rem",
              }}
            >
              <DocumentLeftPanel>
                <RFPStructure
                  onItemClick={(item, parentId) => handleItemClick(item, parentId)}
                  projectId={projectId}
                  archiveIds={archiveIds}
                  refreshItems={(uuid, isNew) =>
                    refreshProjectData(uuid, isNew)
                  }
                />
              </DocumentLeftPanel>
              <DraftEditorPanel
                projectId={projectId}
                userArchiveIDs={archiveIds}
                refreshItems={(uuid, isNew) =>
                  refreshProjectData(uuid, isNew)
                }
                rowData={archives}
                processProjectFile={processProjectFile}
              />

              <DraftRightPanel
                projectId={projectId}
                userArchiveIDs={archiveIds}
                refreshItems={(uuid, isNew) => refreshProjectData(uuid, isNew)}
                rowData={archives}
                fetchProjectData={refreshProjectData}
              />
            </Stack>
          }
          {tab === "documents" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                padding: "0rem 0.5rem",
              }}
            >
              <DocumentLeftPanel>
                <DocumentLeftPanelComponent
                  onItemClick={handleDocumentClick}
                  activeDocument={projectFiles.currentFile}
                  projectId={projectId}
                  availableTagOptions={availableTagOptions}
                  processProjectFile={processProjectFile}
                  fetchProjectFiles={fetchProjectFiles}
                />
              </DocumentLeftPanel>

              <DocumentViewerComponent
                tab={tab}
                pdfViewerRef={pdfViewerRef}
                docxViewerRef={docxViewerRef}
                lastPages={lastPages}
                refreshUrl={refreshUrl}
              />
            </Stack>
          )}
        </Box>
      </Box>
      <Snackbar
        open={exportErr}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon style={{ marginRight: "8px" }} />
              Export failed. Please retry the operation or contact support.
            </span>
          }
        />
      </Snackbar>
      <Snackbar
        open={draftError}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon style={{ marginRight: "8px" }} />
              No draft data found.
            </span>
          }
        />
      </Snackbar>
      <ExportModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectName={projectName}
        onExport={(filename, include_todos) => handleExport(filename, include_todos)}
      />
      {isChatOpen && (
        <ChatWidget
          projectId={projectId}
        />
      )}
      {user?.featureAccess?.generation && (
        <AnimatedChatFab />
      )}
    </Box>
  );
}

export default RFPEditor;
