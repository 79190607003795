import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  Box,
  Paper,
  ListItem,
  ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import ViewToggleButtons from './ViewToggleButtons';
import ActionButtons from './ActionButtons';
import ConfigurationView from './ConfigurationView';
import SourcesView from './SourcesView';
import RequirementsView from './RequirementsView';
import { api_external } from "../../store";
import env from "../../config";
import { updateItemStatus } from "../../reduxstore/itemsStatusSlice";

const DraftRightPanelContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "calc(30% - 8px)",
  minWidth: "calc(30% - 8px)",
  maxWidth: "calc(30% - 8px)",
  height: "100%",
  border: "1px solid #ddd",
  ...theme.typography.body2,
  overflow: "auto",
}));

const DraftRightPanel = ({ projectId, fetchProjectData, userArchiveIDs, rowData, refreshItems }) => {
  const [expanded, setExpanded] = useState(false);
  const [view, setView] = useState("configuration");
  const reqsRef = useRef(null);
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);
  const dispatch = useDispatch();
  const [sourceSearchOpen, setSourceSearchOpen] = useState(false);
  const [regenDialogOpen, setRegenDialogOpen] = useState(false);
  const [dontShowRegenWarning, setDontShowRegenWarning] = useState(false);

  useEffect(() => {

    setView("configuration");

  }, [selectedItem.uuid]);

  const { sourcesToDisplay } = useMemo(() => {
    try {
      let sources = [];
      if (!selectedItem)
        return { sourcesToDisplay: [] };

      if (selectedItem.updated_config?.sources) {
        sources = selectedItem.updated_config.sources;
      } else {
        console.log("no sources found in item:", selectedItem);
      }

      return { sourcesToDisplay: sources };
    } catch (error) {
      console.error("Error getting sources:", error);
      return { sourcesToDisplay: [] };
    }
  }, [selectedItem, selectedItem?.updated_config?.sources, itemsStatus]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const initiateRegeneration = () => {
    const skipWarning = localStorage.getItem('skipRegenWarning') === 'true';
    if (skipWarning) {
      handleRegenerate();
    } else {
      setRegenDialogOpen(true);
    }
  };

  const handleRegenerate = async () => {
    if (dontShowRegenWarning) {
      localStorage.setItem('skipRegenWarning', 'true');
    }
    setRegenDialogOpen(false);

    dispatch(updateItemStatus({ uuid: selectedItem.uuid, isGenerating: true }));
    try {
      const request_data = {
        project_id: projectId,
        node_id: selectedItem.uuid,
        archive_ids: userArchiveIDs,
      };
      await api_external.post(`${env.salesPubAPI}/regenerate-node`, request_data);
      dispatch(updateItemStatus({ uuid: selectedItem.uuid, isGenerating: false }));
      refreshItems(selectedItem.uuid, false);
    } catch (error) {
      console.error("Error regenerating node response:", error);
      dispatch(updateItemStatus({ uuid: selectedItem.uuid, isGenerating: false }));
    }
  }

  return (
    <DraftRightPanelContainer>
      <Box sx={{
        width: "100%",
        height: "3rem",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
      }}>
        <ViewToggleButtons
          view={view}
          handleViewChange={handleViewChange}
          selectedItem={selectedItem}
        />
        <ActionButtons
          onRegenerate={initiateRegeneration}
          selectedItem={selectedItem}
        />
      </Box>
      <Box sx={{
        width: "100%",
        maxWidth: "100%",
        height: "1px",
        backgroundColor: '#ddd',
        margin: '0 auto',
      }} />

      <Box sx={{ maxHeight: '100%', overflow: 'auto' }}>

        {view === "configuration" ? (
          <ConfigurationView
            selectedItem={selectedItem}
            itemStatus={itemsStatus.find(status => status.uuid === selectedItem.uuid)}
            projectId={projectId}
          />
        ) : view === "sources" ? (
          <SourcesView
            sourceSearchOpen={sourceSearchOpen}
            setSourceSearchOpen={setSourceSearchOpen}
            sourcesToDisplay={sourcesToDisplay}
            expanded={expanded}
            setExpanded={setExpanded}
            userArchiveIDs={userArchiveIDs}
            rowData={rowData}
            selectedItem={selectedItem}
            itemStatus={itemsStatus.find(status => status.uuid === selectedItem.uuid)}
            projectId={projectId}
          />

        ) : selectedItem.requirements && selectedItem.requirements.length > 0 ? (
          <RequirementsView
            reqsRef={reqsRef}
            projectId={projectId}
            userArchiveIDs={userArchiveIDs}
            fetchProjectData={fetchProjectData}
            itemStatus={itemsStatus.find(status => status.uuid === selectedItem.uuid)}
          />
        ) : (
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "0.875rem",
                  lineHeight: 1.2,
                  color: "#757575",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                },
              }}
              primary="No requirements available."
            />
          </ListItem>
        )}


      </Box>

      <Dialog
        open={regenDialogOpen}
        onClose={() => setRegenDialogOpen(false)}
      >
        <DialogTitle variant="sectionHeading" sx={{ color: "black" }}>Confirm Regeneration</DialogTitle>
        <DialogContent>
          <Typography variant="primaryText" sx={{ mb: 2 }}>
            This will generate entirely new content for this section. You will lose all your current content. Are you sure you want to continue?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowRegenWarning}
                onChange={(e) => setDontShowRegenWarning(e.target.checked)}
                size="small"
              />
            }
            label={<Typography variant="primaryText">Don't show this warning again</Typography>}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRegenDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRegenerate} variant="contained">Continue</Button>
        </DialogActions>
      </Dialog>
    </DraftRightPanelContainer>
  );
};

export default DraftRightPanel;